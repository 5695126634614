import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsButton, StatusCell, DateCell, BasicModal } from 'common/components';
import UserActivity from 'backoffice/_components/UserActivity';
import { openModal /* , closeModal */ } from 'interface/user/_redux/actions';
import { generateSecurityPinCode } from 'backoffice/users/_redux';
import { EditIcon, BasketIcon, ReloadIcon } from 'common/icons';
import { StatusPlate } from 'client/statuses/_components';
import GenerateAndDownloadSumSubReportForm from '../GenerateAndDownloadSumSubReportForm';
import UserWalletPaymentForm from '../UserWalletPaymentForm';
import arrowTopKYC from './arrowTopKYC.svg';
import { staticStyles } from './style';

export const UserInfoAccountInformation = ({ getLpaInfo, hasLpaEditPerm, hasLpaViewPerm, isSumSubEnabled }) => {
  const dispatch = useDispatch();
  const [openBlock, setOpenBlock] = useState(true);
  const user = useSelector(state => state.backoffice.users.user);
  const kycSettings = useSelector(state => state.interfaceConfig.kycSettings);
  const profile = useSelector(state => state.user.profile);
  const addons = useSelector(state => state.interfaceConfig.addons);

  const CLIENT_TYPES = {
    new: 'justNew',
    retail_individual: 'justRetailIndividual',
    retail_joined: 'justRetailJoined',
    corporate: 'justCorporate',
    ib: 'justIB',
    payment_agent: 'justPaymentAgent',
  };

  useEffect(() => {
    if (user.lpaStatus?.is_lpa) {
      getLpaInfo(user.id);
    }
  }, [user]);

  const [generateSumSubReport, setGerateSumSubReport] = useState(false);
  const toggleModalGenerateSumSubReport = () => setGerateSumSubReport(!generateSumSubReport);

  const [isUserPaymentFormOpened, setIsUserPaymentFormOpened] = useState(false);
  const handleUserPaymentFormToggle = () => {
    setIsUserPaymentFormOpened(isOpen => !isOpen);
  };

  return (
    <div className="UserInfoAccountInformation">
      {/* eslint-disable */}
      <div
        className={classNames('UserInfoAccountInformation__open-close', {
          'UserInfoAccountInformation__open-close--close': !openBlock,
        })}
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <div className="UserInfoAccountInformation__title-ava-wrap">
        <div
          className={classNames('UserInfoAccountInformation__title-wrap', {
            'UserInfoAccountInformation__title-wrap--hide': !openBlock,
          })}>
          <FormattedMessage id="accountInformationBlockKYC">
            {txt => (
              <h2
                className={classNames('UserInfoAccountInformation__title', {
                  'UserInfoAccountInformation__title--hide': !openBlock,
                })}>
                {txt}
              </h2>
            )}
          </FormattedMessage>

          {/* wallet-title */}
          {/* <MediaQuery query="(min-width: 768px)">
            {addons.includes('walletAccount') && (
              <div
                className={classNames('UserInfoAccountInformation__wallet-wrap', {
                  'UserInfoAccountInformation__wallet-wrap--hide': !openBlock,
                })}>
                <div className="UserInfoAccountInformation__wallet-subwrap">
                  <FormattedMessage id="justWalletBalance">
                    {txt => <div className="UserInfoAccountInformation__wallet-title">{txt}</div>}
                  </FormattedMessage>
                  <div className="UserInfoAccountInformation__wallet-data">{`$${user.walletBalance}`}</div>
                </div>
              </div>
            )}
          </MediaQuery> */}
        </div>
        <div className="UserInfoAccountInformation__ava-170" />
      </div>
      {/* eslint-disable */}
      <img
        className={classNames('UserInfoAccountInformation__arrowTopKYC', {
          'UserInfoAccountInformation__arrowTopKYC--hide': !openBlock,
        })}
        src={arrowTopKYC}
        alt="arrowTopKYC"
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}

      {/* content */}
      <div className="UserInfoAccountInformation__content-ava-wrap">
        <div
          className={classNames('UserInfoAccountInformation__content', {
            'UserInfoAccountInformation__content--hide': !openBlock,
          })}>
          <div className="UserInfoAccountInformation__content-left">
            {/* reg-date */}
            {user.dateJoined && (
              <>
                <div className="UserInfoAccountInformation__reg-date-wrap">
                  <FormattedMessage id="kycDateRegistration">
                    {txt => <div className="UserInfoAccountInformation__reg-date-title">{txt}</div>}
                  </FormattedMessage>
                  <div className="UserInfoAccountInformation__reg-date-data">
                    <DateCell value={user.dateJoined} />
                  </div>
                </div>
                <div className="UserInfoAccountInformation__reg-date-wrap">
                  <FormattedMessage id="kycDateAgreements">
                    {txt => <div className="UserInfoAccountInformation__reg-date-title">{txt}</div>}
                  </FormattedMessage>
                  <FormattedDate month="long" day="2-digit" year="numeric" value={user.dateJoined} />
                </div>
              </>
            )}

            {/* last-activity */}
            {user.lastSeen && (
              <div className="UserInfoAccountInformation__last-activity-wrap">
                <FormattedMessage id="justActivity">
                  {txt => <div className="UserInfoAccountInformation__last-activity-title">{txt}</div>}
                </FormattedMessage>
                <div className="UserInfoAccountInformation__last-activity-data">
                  <UserActivity isBlocked={!user.isActive} isOnline={user.isOnline} lastSeen={user.lastSeen} />
                </div>
              </div>
            )}

            {/* user-status */}
            {kycSettings.enabled && user.isActive && (
              <div className="UserInfoAccountInformation__verification-status-wrap">
                <div className="UserInfoAccountInformation__verification-status-title">
                  <FormattedMessage id="justVerificationStatus">{txt => txt}</FormattedMessage>
                </div>
                <div className="UserInfoAccountInformation__verification-status-data">
                  <StatusCell statusCode={user.status} />
                  <ActionsButton
                    edit
                    buttonId="actionVerification"
                    condition={!!(profile.perms.actionUser || profile.perms.managerActionUser) && !user.isStaff}
                    onClickFunc={() => dispatch(openModal('userVerify'))}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}

            {/* sumsub-id */}
            {kycSettings.enabled && kycSettings.kyc_verifier === 'sumsub' && user.sumsubId && (
              <div className="UserInfoAccountInformation__sumsub-id-wrap">
                <div className="UserInfoAccountInformation__sumsub-id-title">
                  <FormattedMessage id="justSumsubId">{txt => txt}</FormattedMessage>
                </div>
                <div className="UserInfoAccountInformation__sumsub-id-data">{user.sumsubId}</div>
              </div>
            )}

            {/* profile-validity */}
            <div className="UserInfoAccountInformation__profile-validity-wrap">
              <FormattedMessage id="justProfileValidity">
                {txt => <div className="UserInfoAccountInformation__profile-validity-title">{txt}</div>}
              </FormattedMessage>
              <div className="UserInfoAccountInformation__profile-validity-data">
                <StatusCell statusCode={user.isActive ? 'valid' : 'blocked'} />
                <ActionsButton
                  edit
                  buttonId="actionBlockUser"
                  condition={!!(profile.perms.actionUser || profile.perms.managerActionUser) && !user.isStaff}
                  onClickFunc={() => dispatch(openModal('userBlock'))}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* sumsub-report */}
            {profile.perms.viewUserDocuments && isSumSubEnabled && user.sumsubId && (
              <div className="UserInfoAccountInformation__sumsub-report-wrap">
                <FormattedMessage id="justSumSubReport">
                  {txt => <div className="UserInfoAccountInformation__sumsub-report-title">{txt}</div>}
                </FormattedMessage>
                <button
                  type="button"
                  onClick={() => toggleModalGenerateSumSubReport()}
                  className="UserInfoAccountInformation__sumsub-report-data">
                  Download
                </button>
              </div>
            )}

            {/* support-messages */}
            <div className="UserInfoAccountInformation__support-messages-wrap">
              <FormattedMessage id="justSupportMessages">
                {txt => <div className="UserInfoAccountInformation__support-messages-title">{txt}</div>}
              </FormattedMessage>
              <div className="UserInfoAccountInformation__support-messages-data">
                <StatusCell statusCode={user.supportMessages ? 'allow' : 'disallow'} />
                <ActionsButton
                  edit
                  buttonId="actionBlockUser"
                  condition={!!(profile.perms.actionUser || profile.perms.managerActionUser) && !user.isStaff}
                  onClickFunc={() => dispatch(openModal('userSupport'))}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>
            {/* local payment agent (LPA) */}
            {hasLpaViewPerm && user.isActive && user.status !== 'incomplete' && profile.lpa && (
              <div className="UserInfoAccountInformation__lpa-wrap">
                <FormattedMessage id="justLPA">
                  {txt => <div className="UserInfoAccountInformation__lpa-title">{txt}</div>}
                </FormattedMessage>
                <div className="UserInfoAccountInformation__lpa-data">
                  <StatusCell statusCode={user.lpaStatus.is_lpa ? 'appoint' : 'not_appointed'} />
                  <ActionsButton
                    edit
                    buttonId="actionBlockUser"
                    condition={hasLpaEditPerm}
                    onClickFunc={() => dispatch(openModal('userLpa'))}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}
          </div>

          <div className="UserInfoAccountInformation__content-right">
            {/* wallet-balance */}
            {addons.includes('walletAccount') && (
              <div className="UserInfoAccountInformation__wallet-balance-wrap">
                <FormattedMessage id="justWalletBalance">
                  {txt => <div className="UserInfoAccountInformation__wallet-balance-title">{txt}</div>}
                </FormattedMessage>
                <div className="UserInfoAccountInformation__wallet-balance-data-wrap">
                  <div className="UserInfoAccountInformation__wallet-balance-data">
                    {user.walletBalance ? `$${user.walletBalance}` : '*******'}
                  </div>
                  <ActionsButton
                    edit
                    buttonId="editClientType"
                    condition={profile.perms.walletBalanceCorrectionPerm || profile.isAdmin}
                    onClickFunc={handleUserPaymentFormToggle}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}
            {/* client-type */}
            <div className="UserInfoAccountInformation__client-status-wrap">
              <div className="UserInfoAccountInformation__client-status-title">
                <FormattedMessage id="kycClientType">{txt => txt}</FormattedMessage>
              </div>
              <div className="UserInfoAccountInformation__client-status-data">
                <FormattedMessage id={user.clientType ? CLIENT_TYPES[user.clientType] : '---'}>
                  {txt => <span>{txt}</span>}
                </FormattedMessage>
                <ActionsButton
                  edit
                  buttonId="editClientType"
                  condition={!!(profile.perms.actionUser || profile.perms.managerActionUser) && !user.isStaff}
                  onClickFunc={() => dispatch(openModal('editClientType'))}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* verification-status */}
            {addons.includes('statusesSystem') && (
              <div className="UserInfoAccountInformation__client-status-wrap">
                <div className="UserInfoAccountInformation__client-status-title">
                  <FormattedMessage id="justClientStatus">{txt => txt}</FormattedMessage>
                </div>
                <div className="UserInfoAccountInformation__client-status-data">
                  {user.currentStatus ? (
                    <StatusPlate
                      title={user.currentStatus.title}
                      level={user.currentStatus.level}
                      statusColor={user.currentStatus.statusColor}
                    />
                  ) : (
                    <StatusCell statusCode="none" />
                  )}
                  <ActionsButton
                    edit
                    buttonId="editStatus"
                    condition={!!profile.perms.editStatusPerm}
                    onClickFunc={() => dispatch(openModal('editStatus'))}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}

            {/* password */}
            <div className="UserInfoAccountInformation__password-wrap">
              <FormattedMessage id="authPassword">
                {txt => <div className="UserInfoAccountInformation__password-title">{txt}</div>}
              </FormattedMessage>
              <div className="UserInfoAccountInformation__password-data">
                <span>*******</span>
                <ActionsButton
                  edit
                  buttonId="actionUserPassword"
                  condition={!!profile.perms.actionUser}
                  onClickFunc={() => dispatch(openModal('userPasswordChange'))}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* security pin */}
            <div className="UserInfoAccountInformation__security-code-wrap">
              <FormattedMessage id="justSecurityCode">
                {txt => <div className="UserInfoAccountInformation__security-code-title">{txt}</div>}
              </FormattedMessage>
              <div className="UserInfoAccountInformation__security-code-data">
                <span>{user.securityPin ? user.securityPin : '------'}</span>
                <ActionsButton
                  edit
                  tooltipId="generateSecurityCode"
                  tooltipTextId="justRegenerateCode"
                  condition
                  onClickFunc={() => {
                    dispatch(generateSecurityPinCode.request(user.id));
                  }}>
                  <ReloadIcon />
                </ActionsButton>
              </div>
            </div>

            {/* manager */}
            {addons.includes('managerSystem') && (
              <div className="UserInfoAccountInformation__manager-wrap">
                <FormattedMessage id="justManager">
                  {txt => <div className="UserInfoAccountInformation__manager-title">{txt}</div>}
                </FormattedMessage>
                <div className="UserInfoAccountInformation__manager-data">
                  {user.manager ? (
                    <NavLink to={`${user.manager.id}`}>{`${user.manager.firstName} ${user.manager.lastName}`}</NavLink>
                  ) : (
                    <StatusCell statusCode="none" />
                  )}
                  <ActionsButton
                    edit
                    buttonId="editManager"
                    onClickFunc={() => dispatch(openModal('editManager'))}
                    condition={!!profile.perms.setManagerPerm}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}

            {/* partner-code */}
            <div className="UserInfoAccountInformation__partner-code-wrap">
              <FormattedMessage id="partnerCode">
                {txt => <div className="UserInfoAccountInformation__partner-code-title">{txt}</div>}
              </FormattedMessage>
              <div className="UserInfoAccountInformation__partner-code-data">
                {user.partnerInfo ? (
                  <NavLink to={`${user.partnerInfo.partnerId}`}>{user.partnerInfo.partnerCode}</NavLink>
                ) : (
                  <StatusCell statusCode="none" />
                )}
                <ActionsButton
                  edit
                  buttonId="editPartnerCode"
                  onClickFunc={() => dispatch(openModal('editPartnerCode'))}
                  condition={!!profile.perms.editUser || !!profile.perms.managerEditUser}>
                  <EditIcon />
                </ActionsButton>
                {user.partnerInfo?.partnerCode && (
                  <ActionsButton
                    deleteType
                    buttonId="removePartnerCode"
                    onClickFunc={() => dispatch(openModal('removePartnerCode'))}
                    condition={!!profile.perms.editUser || !!profile.perms.managerEditUser}>
                    <BasketIcon />
                  </ActionsButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="UserInfoAccountInformation__content-ava-170" />
      </div>

      <BasicModal
        isOpen={generateSumSubReport}
        captionId="justGenerateAndDownloadSumSubReport"
        onRequestClose={() => toggleModalGenerateSumSubReport()}>
        <GenerateAndDownloadSumSubReportForm toggleModal={toggleModalGenerateSumSubReport} userId={user.id} />
      </BasicModal>

      <BasicModal
        isOpen={isUserPaymentFormOpened}
        onRequestClose={handleUserPaymentFormToggle}
        captionId="boUserChangeWalletBalance">
        <UserWalletPaymentForm onCloseModal={handleUserPaymentFormToggle} userId={user.id} />
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </div>
  );
};
