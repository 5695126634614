import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getStates } from 'client/KYC/_redux';

import { openModal, closeModal } from 'interface/user/_redux/actions';
import { requestEmailConfirmation } from 'auth/resendEmailConfirmation/_redux';
import { BasicModal, DefaultButton, PageContentPreloader, PasswordChangeForm, Tooltip } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';

import classNames from 'classnames';
import { UserInfoPanel, UserNoInfoPanel, InfoLine } from '../../../_components';

import {
  getUserInfo,
  verifyPartner,
  changeUserPassword,
  confirmUserEmail,
  getLpaInfo,
  getCurrencies,
  setClientType,
  getUserSumSubDocuments,
  downloadUserSumSubDocument,
} from '../../_redux';

import UserEditInfoModal from '../UserEditInfoModal';
import UserActionsModal from '../UserActionsModal';
import UserDocumentsForm from '../UserDocumentsForm';
import UserTagsPanel from '../UserTagsPanel';
import EditUserPartnerCodeForm from '../EditUserPartnerCodeForm';
import RemoveUserPatnerCodeForm from '../RemoveUserPatnerCodeForm';
import EditUserManagerForm from '../EditUserManagerForm';
import EditStatusForm from '../EditStatusForm';
import UserCardsPanel from '../UserCardsPanel';

import RejectPartnerForm from '../RejectPartnerForm';

import { staticStyles, getDynamicStyles } from './style';
import arrowTopKYC from './arrowTopKYC.svg';
import { UserInfoAccountInformation } from '../UserInfoAccountInformation/UserInfoAccountInformation';
import EditClientTypeForm from '../EditClientTypeForm';

const UserInfo = ({
  profile,
  getUserInfo,
  setClientType,
  getCurrencies,
  user,
  getStates,
  isSumSubEnabled,
  states,
  countries,
  userIsFullyLoaded,
  userIsLoaded,
  match,
  verifyPartner,
  getLpaInfo,
  userDocuments,
  userSumSubDocuments,
  userSumSubDocumentsAreLoaded,
  downloadUserSumSubDocument,
  getUserSumSubDocuments,
  documentsUploadErrors,
  kycSettings,
  passport,
  identificationProof,
  addressProof,
  modals,
  openModal,
  closeModal,
  confirmUserEmail,
  requestEmailConfirmation,
  addons,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const isUserLpa = user.lpaStatus?.is_lpa;
  const hasLpaViewPerm = profile.perms.managerLpaViewPerm || profile.perms.lpaViewPerm || false;
  const hasLpaEditPerm = profile.perms.managerLpaEditPerm || profile.perms.lpaEditPerm || false;

  let timer;

  useEffect(() => () => clearInterval(timer), []);

  useEffect(() => {
    getUserInfo(match.params.id);
  }, [getUserInfo, match.params.id]);

  const [sumSubDocsLoaded, setSumSubDocsLoaded] = useState(false);

  useEffect(() => {
    if (user && user.sumsubId && !sumSubDocsLoaded) {
      getUserSumSubDocuments(match.params.id);
      setSumSubDocsLoaded(true);
    }
  }, [getUserSumSubDocuments, user, match.params.id, sumSubDocsLoaded]);

  useEffect(() => {
    if (hasLpaEditPerm) {
      getCurrencies();
    }
  }, [getCurrencies]);

  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);

  const address = user && user.dataKyc && user.dataKyc.address;
  const hasAdditionalInfo = address && !!Object.keys(address).find(key => !!address[key]);

  const handleConfirm = () => {
    setLoading(true);
    verifyPartner(match.params.id);
  };

  const handleConfirmEmail = () => {
    setLoading(true);
    confirmUserEmail({ userId: match.params.id });
  };

  const decreaseResendTimer = () =>
    setResendTimer(time => {
      if (time === 0) {
        clearInterval(timer);
        setResendDisabled(false);

        return 30;
      }

      return time - 1;
    });

  const handleResendEmail = () => {
    setResendDisabled(true);
    requestEmailConfirmation({ email: user.email });
    decreaseResendTimer();
    timer = setInterval(decreaseResendTimer, 1000);
  };

  let hasTradingExperience;
  if (userIsFullyLoaded) {
    if (
      user.dataKyc.experience.forex ||
      user.dataKyc.experience.derivative ||
      user.dataKyc.experience.securities ||
      user.dataKyc.experience.investmentFund
    ) {
      hasTradingExperience = 'data';
    } else if (user.status === 'incomplete') {
      hasTradingExperience = 'noInfoPanel';
    }
  }

  const userEditData = {
    userId: user.id,
    data: [
      {
        form: 'user-change-email',
        captionId: 'boUserChangeEmail',
        isOpen: modals.userChangeEmail,
        onRequestClose: () => closeModal('userChangeEmail'),
        fieldName: 'email',
        fieldTitle: 'justEmail',
        fieldType: 'email',
        initialValue: { email: user.email },
      },
      {
        form: 'user-change-phone',
        captionId: 'boUserChangePhone',
        isOpen: modals.userChangePhone,
        onRequestClose: () => closeModal('userChangePhone'),
        fieldName: 'phone',
        fieldTitle: 'justPhone',
        fieldType: 'tel',
        initialValue: { phone: user.phone },
      },
      {
        form: 'user-change-last-name',
        captionId: 'boUserChangeLastName',
        isOpen: modals.userChangeLastName,
        onRequestClose: () => closeModal('userChangeLastName'),
        fieldName: 'last_name',
        fieldTitle: 'justLastName',
        fieldType: 'text',
        initialValue: { last_name: user.lastName },
      },
      {
        form: 'user-change-first-name',
        captionId: 'boUserChangeFirstName',
        isOpen: modals.userChangeFirstName,
        onRequestClose: () => closeModal('userChangeFirstName'),
        fieldName: 'first_name',
        fieldTitle: 'kycFirstName',
        fieldType: 'text',
        initialValue: { first_name: user.firstName },
      },
      {
        form: 'user-change-middle-name',
        captionId: 'boUserChangeMiddleName',
        isOpen: modals.userChangeMiddleName,
        onRequestClose: () => closeModal('userChangeMiddleName'),
        fieldName: 'middle_name',
        fieldTitle: 'kycMiddleName',
        fieldType: 'text',
        initialValue: { middle_name: user.middleName },
      },
      {
        form: 'user-change-birthday',
        captionId: 'boUserChangeBirthday',
        isOpen: modals.userChangeBirthday,
        onRequestClose: () => closeModal('userChangeBirthday'),
        fieldName: 'birthday',
        fieldTitle: 'kycBirthday',
        fieldType: 'date',
        initialValue: { birthday: userIsFullyLoaded && user.dataKyc.birthday },
      },
      {
        form: 'user-change-nationality',
        captionId: 'boUserChangeNationality',
        isOpen: modals.userChangeNationality,
        onRequestClose: () => closeModal('userChangeNationality'),
        fieldName: 'nationality',
        fieldTitle: 'kycNationality',
        initialValue: {
          nationality: userIsFullyLoaded && user.dataKyc.nationality,
        },
      },
      {
        form: 'user-change-tax-residence',
        captionId: 'boUserChangeTaxResidence',
        isOpen: modals.userChangeTaxResidence,
        onRequestClose: () => closeModal('userChangeTaxResidence'),
        fieldName: 'tax_residence',
        fieldTitle: 'kycTaxResidence',
        initialValue: {
          tax_residence: userIsFullyLoaded && user.dataKyc.taxResidence,
        },
      },
      {
        form: 'user-change-extension-number',
        captionId: 'justChangeExtensionNumber',
        isOpen: modals.editExtensionNumber,
        onRequestClose: () => closeModal('editExtensionNumber'),
        fieldName: 'extension_number',
        fieldTitle: 'justExtensionNumber',
        fieldType: 'number',
        initialValue: {
          extension_number: user.extensionNumber,
        },
      },
    ],
  };

  const [openBlockA, setOpenBlockA] = useState(false);
  const [openBlockTE, setOpenBlockTE] = useState(false);

  const customPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])|(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
  const customPasswordLengthOptions = { min: 6 };

  return (
    <PageContentPreloader ready={userIsLoaded} type="boUserInfo">
      <div className="UserInfo">
        {profile.perms.partnershipUser && (!user.isPartner || user.isRebate) && user.isPartnershipRequested && (
          <div className="UserInfo__partnership-inner">
            <FormattedMessage id="boSentPartnership">{txt => <p>{txt}</p>}</FormattedMessage>
            <div className="UserInfo__partnership-buttons">
              <DefaultButton
                onClick={handleConfirm}
                textId="justConfirm"
                butColor="success"
                loading={loading}
                disabled={loading}
              />
              <DefaultButton
                onClick={() => openModal('rejectPartner')}
                textId="boReject"
                butColor="error"
                disabled={loading}
              />
            </div>
          </div>
        )}
        {!user.emailConfirmed && profile.perms.viewUserEmail && (
          <div className="UserInfo__partnership-inner">
            <FormattedMessage id="userEmailIsNotConfirmed">{txt => <p>{txt}</p>}</FormattedMessage>
            <div className="UserInfo__partnership-buttons">
              <DefaultButton
                onClick={handleConfirmEmail}
                textId="justConfirm"
                butColor="success"
                loading={loading}
                disabled={loading}
              />
              {resendDisabled ? (
                <Tooltip
                  id="resendbutton-with-timer-tooltip-id"
                  textId="justWaitSec"
                  formattedValues={{ timer: resendTimer }}>
                  <DefaultButton
                    onClick={handleResendEmail}
                    textId="authDoResendEmailConfirmation"
                    butColor="success"
                    disabled={resendDisabled}
                  />
                </Tooltip>
              ) : (
                <DefaultButton
                  onClick={handleResendEmail}
                  textId="authDoResendEmailConfirmation"
                  butColor="success"
                  disabled={resendDisabled}
                />
              )}
            </div>
          </div>
        )}

        <div className="UserInfo__inform-inner">
          <UserInfoPanel
            user={user}
            getStates={getStates}
            states={states}
            countries={countries}
            profile={profile}
            addons={addons}
            kycSettings={kycSettings}
            userInfoPage
            onUserEmailChangeOpenModal={() => openModal('userChangeEmail')}
            onUserPhoneChangeOpenModal={() => openModal('userChangePhone')}
            onUserPhoneRemoveOpenModal={() => openModal('userRemovePhone')}
            onUserLastNameChangeOpenModal={() => openModal('userChangeLastName')}
            onUserFirstNameChangeOpenModal={() => openModal('userChangeFirstName')}
            onUserMiddleNameChangeOpenModal={() => openModal('userChangeMiddleName')}
            onUserBirthdayChangeOpenModal={() => openModal('userChangeBirthday')}
            onUserNationalityChangeOpenModal={() => openModal('userChangeNationality')}
            onUserTaxResidenceChangeOpenModal={() => openModal('userChangeTaxResidence')}
            onUserVerifyOpenModal={() => openModal('userVerify')}
            onUserBlockOpenModal={() => openModal('userBlock')}
            onUserPartnerOpenModal={() => openModal('rejectPartner')}
            onPartnerChangeOpenModal={() => openModal('changePartner')}
            onPasswordResetOpenModal={() => openModal('userPasswordChange')}
            onPartnerCodeEditModal={() => openModal('editPartnerCode')}
            onManagerEditModal={() => openModal('editManager')}
            onStatusEditModal={() => openModal('editStatus')}
            onUserExtensionNumberEditModal={() => openModal('editExtensionNumber')}
          />

          <UserInfoAccountInformation
            getLpaInfo={getLpaInfo}
            isSumSubEnabled={isSumSubEnabled}
            hasLpaEditPerm={hasLpaEditPerm}
            hasLpaViewPerm={hasLpaViewPerm}
          />

          {(profile.perms.tagsUser || profile.perms.managerTagsUser) && <UserTagsPanel userId={user.id} />}

          {profile.perms.viewUserDocuments &&
            (passport.enable || identificationProof.enable || addressProof.enable) && (
              <UserDocumentsForm
                documents={userDocuments}
                user={user}
                documentsUploadErrors={documentsUploadErrors}
                sumSubDocuments={userSumSubDocuments}
                sumSubDocumentsAreLoaded={userSumSubDocumentsAreLoaded}
                downloadSumSubDocument={downloadUserSumSubDocument}
              />
            )}

          {(profile.perms.cardsUser || profile.perms.managerCardsUser) && <UserCardsPanel />}

          <div className="UserInfoAddress UserInfo__inform-block UserInfo__inform-block_two-inners">
            {/* eslint-disable */}
            <div
              className={classNames('UserInfoAddress__open-close', {
                'UserInfoAddress__open-close--close': !openBlockA,
              })}
              onClick={() => setOpenBlockA(!openBlockA)}
            />
            {/* eslint-enable */}
            <FormattedMessage id="boAdditionalInform">
              {txt => (
                <h2
                  className={classNames('UserInfoAddress__title', {
                    'UserInfoAddress__title--hide': !openBlockA,
                  })}>
                  {txt}
                </h2>
              )}
            </FormattedMessage>
            {/* eslint-disable */}
            <img
              className={classNames('UserInfoAddress__arrowTopKYC', {
                'UserInfoAddress__arrowTopKYC--hide': !openBlockA,
              })}
              src={arrowTopKYC}
              alt="arrowTopKYC"
              onClick={() => setOpenBlockA(!openBlockA)}
            />
            {/* eslint-enable */}
            <div
              className={classNames('UserInfoAddress__content', {
                'UserInfoAddress__content--hide': !openBlockA,
              })}>
              {!addons.includes('trederoCustomKyc') && !hasAdditionalInfo && (
                <UserNoInfoPanel image={noInfoImage} textId="boAdditionalNoInform" />
              )}
              {!addons.includes('trederoCustomKyc') && hasAdditionalInfo && (
                <>
                  <div className="UserInfoAddress__address-block Address-block">
                    <FormattedMessage id="kycAddress">{txt => <h2>{txt}</h2>}</FormattedMessage>
                    <div className="UserInfo__inform-block_text">
                      {user && (
                        <>
                          <InfoLine textId="kycCountry" data={user.country} />
                          <InfoLine textId="kycState" data={user.state} />
                        </>
                      )}
                      <InfoLine textId="kycCity" data={address && address.city} />
                      <InfoLine textId="kycAddress" data={address && address.address} />
                    </div>
                  </div>
                  <div className="UserInfoAddress__financial-details-block FinancialDetails-block">
                    <FormattedMessage id="kycFinDetails">{txt => <h2>{txt}</h2>}</FormattedMessage>
                    <div className="UserInfo__inform-block_text">
                      <InfoLine
                        textId="kycNetCapital"
                        data={user.dataKyc.finance && user.dataKyc.finance.capital}
                        forTranslate
                      />
                      <InfoLine
                        textId="kycMonFinCommit"
                        data={user.dataKyc.finance && user.dataKyc.finance.commitments}
                        forTranslate
                      />
                      <InfoLine
                        textId="kycAnnualIncome"
                        data={user.dataKyc.finance && user.dataKyc.finance.income}
                        forTranslate
                      />
                      <InfoLine
                        textId="avgVolumeOfTransPerYear"
                        data={user.dataKyc.finance && user.dataKyc.finance.investments}
                      />
                      <InfoLine
                        textId="kycAnnualIncome"
                        data={user.dataKyc.finance && user.dataKyc.finance.purpose}
                        forTranslate
                      />
                    </div>
                  </div>
                  <div className="UserInfoAddress__type-of-your-activity-block">
                    <FormattedMessage id="kycTypeOfActivity">{txt => <h2>{txt}</h2>}</FormattedMessage>
                    <div className="UserInfo__inform-block_text">
                      <InfoLine
                        textId="kycEmplStatus"
                        data={user.dataKyc.activity && user.dataKyc.activity.employment}
                        forTranslate
                      />
                      <InfoLine
                        textId="kycSourceOfFunds"
                        data={user.dataKyc.activity && user.dataKyc.activity.source}
                        forTranslate
                      />
                      <InfoLine
                        textId="kycNatureOfBusiness"
                        data={user.dataKyc.activity && user.dataKyc.activity.business}
                        forTranslate
                      />
                      <InfoLine
                        textId="kycLvlOfEdu"
                        data={user.dataKyc.activity && user.dataKyc.activity.education}
                        forTranslate
                      />
                    </div>
                  </div>
                </>
              )}

              {addons.includes('trederoCustomKyc') && !hasAdditionalInfo && (
                <UserNoInfoPanel image={noInfoImage} textId="boAdditionalNoInform" />
              )}
              {addons.includes('trederoCustomKyc') && hasAdditionalInfo && (
                <>
                  <div className="UserInfoAddress__address-block Address-block">
                    <FormattedMessage id="kycAddress">{txt => <h2>{txt}</h2>}</FormattedMessage>
                    <div className="UserInfo__inform-block_text">
                      {user && (
                        <>
                          <InfoLine textId="kycCountry" data={user.country} />
                          <InfoLine textId="kycState" data={user.state} />
                        </>
                      )}
                      <InfoLine textId="kycCity" data={address && address.city} />
                      <InfoLine textId="kycAddress" data={address && address.address} />
                    </div>
                  </div>
                  <div className="UserInfoAddress__financial-details-block FinancialDetails-block">
                    <FormattedMessage id="kycFinDetails">{txt => <h2>{txt}</h2>}</FormattedMessage>
                    <div className="UserInfo__inform-block_text">
                      <InfoLine textId="trederoCustomKYCIndustry" data={user.dataKyc.industry} forTranslate />
                      <InfoLine
                        textId="trederoCustomKYCEstimatedAnnualIncome"
                        data={user.dataKyc.estimatedAnnualIncome}
                        forTranslate
                      />
                      <InfoLine
                        textId="trederoCustomKYCEstimatedNetWorth"
                        data={user.dataKyc.estimatedNetWorth}
                        forTranslate
                      />
                      <InfoLine textId="trederoCustomKYCSavings" data={user.dataKyc.savings} forTranslate />
                      <InfoLine
                        textId="trederoCustomKYCSourceOfIncome"
                        data={user.dataKyc.sourceOfIncome}
                        forTranslate
                      />
                    </div>
                  </div>
                  <div className="UserInfoAddress__type-of-your-activity-block">
                    <FormattedMessage id="kycTypeOfActivity">{txt => <h2>{txt}</h2>}</FormattedMessage>
                    <div className="UserInfo__inform-block_text">
                      <InfoLine textId="trederoCustomKYCExpectation" data={user.dataKyc.expectation} forTranslate />
                      <InfoLine textId="trederoCustomKYCPurpose" data={user.dataKyc.purpose} forTranslate />
                      <InfoLine textId="trederoCustomKYCFXCFD" data={user.dataKyc.fxCfd} forTranslate />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {!addons.includes('trederoCustomKyc') && hasTradingExperience === 'data' && (
            <div className="TradingExperience UserInfo__inform-block">
              {/* eslint-disable */}
              <div
                className={classNames('TradingExperience__open-close', {
                  'TradingExperience__open-close--close': !openBlockTE,
                })}
                onClick={() => setOpenBlockTE(!openBlockTE)}
              />
              {/* eslint-enable */}
              <div className="TradingExperience-block">
                <FormattedMessage id="kycTradingExp">
                  {txt => (
                    <h2
                      className={classNames('TradingExperience__title', {
                        'TradingExperience__title--hide': !openBlockTE,
                      })}>
                      {txt}
                    </h2>
                  )}
                </FormattedMessage>
                {/* eslint-disable */}
                <img
                  className={classNames('TradingExperience__arrowTopKYC', {
                    'TradingExperience__arrowTopKYC--hide': !openBlockTE,
                  })}
                  src={arrowTopKYC}
                  alt="arrowTopKYC"
                  onClick={() => setOpenBlockTE(!openBlockTE)}
                />
                {/* eslint-enable */}
                <div
                  className={classNames('TradingExperience__content UserInfo__inform-block_text', {
                    'TradingExperience__content--hide': !openBlockTE,
                  })}>
                  {user.dataKyc.experience.forex && (
                    <div className="TradingExperience__small-block">
                      <div className="UserInfo__line-inner">
                        <FormattedMessage id="kycForexCdfs">{txt => <h3>{txt}</h3>}</FormattedMessage>
                      </div>
                      <InfoLine
                        textId="freqOfTransSelect"
                        data={user.dataKyc.experience.forex.frequency}
                        forTranslate
                      />
                      <InfoLine textId="trainingRecieved" data={user.dataKyc.experience.forex.training} forTranslate />
                      <InfoLine
                        textId="avgVolumeOfTransPerYear"
                        data={user.dataKyc.experience.forex.volume}
                        forTranslate
                      />
                      <InfoLine textId="yearsOfExp" data={user.dataKyc.experience.forex.years} forTranslate />
                    </div>
                  )}

                  {user.dataKyc.experience.derivative && (
                    <div className="TradingExperience__small-block">
                      <div className="UserInfo__line-inner">
                        <FormattedMessage id="kycDerivativeInstr">{txt => <h3>{txt}</h3>}</FormattedMessage>
                      </div>
                      <InfoLine
                        textId="freqOfTransSelect"
                        data={user.dataKyc.experience.derivative.frequency}
                        forTranslate
                      />
                      <InfoLine
                        textId="trainingRecieved"
                        data={user.dataKyc.experience.derivative.training}
                        forTranslate
                      />
                      <InfoLine
                        textId="avgVolumeOfTransPerYear"
                        data={user.dataKyc.experience.derivative.volume}
                        forTranslate
                      />
                      <InfoLine textId="yearsOfExp" data={user.dataKyc.experience.derivative.years} forTranslate />
                    </div>
                  )}

                  {user.dataKyc.experience.securities && (
                    <div className="TradingExperience__small-block">
                      <div className="UserInfo__line-inner">
                        <FormattedMessage id="kycTransfSec">{txt => <h3>{txt}</h3>}</FormattedMessage>
                      </div>
                      <InfoLine
                        textId="freqOfTransSelect"
                        data={user.dataKyc.experience.securities.frequency}
                        forTranslate
                      />
                      <InfoLine
                        textId="trainingRecieved"
                        data={user.dataKyc.experience.securities.training}
                        forTranslate
                      />
                      <InfoLine
                        textId="avgVolumeOfTransPerYear"
                        data={user.dataKyc.experience.securities.volume}
                        forTranslate
                      />
                      <InfoLine textId="yearsOfExp" data={user.dataKyc.experience.securities.years} forTranslate />
                    </div>
                  )}

                  {user.dataKyc.experience.investmentFund && (
                    <div className="TradingExperience__small-block">
                      <div className="UserInfo__line-inner">
                        <FormattedMessage id="kycUnitsCollectiveInv">{txt => <h3>{txt}</h3>}</FormattedMessage>
                      </div>
                      <InfoLine
                        textId="freqOfTransSelect"
                        data={user.dataKyc.experience.investmentFund.frequency}
                        forTranslate
                      />
                      <InfoLine
                        textId="trainingRecieved"
                        data={user.dataKyc.experience.investmentFund.training}
                        forTranslate
                      />
                      <InfoLine
                        textId="avgVolumeOfTransPerYear"
                        data={user.dataKyc.experience.investmentFund.volume}
                        forTranslate
                      />
                      <InfoLine textId="yearsOfExp" data={user.dataKyc.experience.investmentFund.years} forTranslate />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!addons.includes('trederoCustomKyc') && hasTradingExperience === 'noInfoPanel' && (
            <div className="TradingExperience UserInfo__inform-block">
              <div className="TradingExperience-block">
                <FormattedMessage id="kycTradingExp">
                  {txt => (
                    <h2
                      className={classNames('TradingExperience__title', {
                        'TradingExperience__title--hide': !openBlockTE,
                      })}>
                      {txt}
                    </h2>
                  )}
                </FormattedMessage>
                {/* eslint-disable */}
                <img
                  className={classNames('TradingExperience__arrowTopKYC', {
                    'TradingExperience__arrowTopKYC--hide': !openBlockTE,
                  })}
                  src={arrowTopKYC}
                  alt="arrowTopKYC"
                  onClick={() => setOpenBlockTE(!openBlockTE)}
                />
                {/* eslint-enable */}
                <div
                  className={classNames('TradingExperience__content UserInfo__inform-block_text', {
                    'TradingExperience__content--hide': !openBlockTE,
                  })}>
                  <UserNoInfoPanel image={noInfoImage} textId="boTradingExperienceNoInfo" />
                </div>
              </div>
            </div>
          )}
        </div>

        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </div>

      {userEditData.data.map((item, index) => (
        <UserEditInfoModal
          key={index}
          form={item.form}
          userId={userEditData.userId}
          captionId={item.captionId}
          isOpen={item.isOpen}
          onRequestClose={item.onRequestClose}
          fieldName={item.fieldName}
          fieldTitle={item.fieldTitle}
          fieldType={item.fieldType}
          initialValues={item.initialValue}
        />
      ))}

      <UserActionsModal
        user={user}
        isOpen={modals.userVerify}
        captionId="boVerificationActionsTitle"
        descriptionId="boVerificationActionsDescription"
        onRequestClose={() => closeModal('userVerify')}
        statusBlock
      />

      <UserActionsModal
        user={user}
        isOpen={modals.userRemovePhone}
        captionId="boUserRemovePhone"
        descriptionId="boRemovePhoneActionsDescription"
        onRequestClose={() => closeModal('userRemovePhone')}
        userRemovePhone
      />

      <UserActionsModal
        user={user}
        isOpen={modals.userBlock}
        captionId={user.isActive ? 'boBlockUserTitle' : 'boUnblockUserTitle'}
        descriptionId={user.isActive ? 'boBlockUserDescription' : 'boUnblockUserDescription'}
        onRequestClose={() => closeModal('userBlock')}
        activeBlock
      />

      <UserActionsModal
        user={user}
        isOpen={modals.userSupport}
        captionId={user.supportMessages ? 'boBlockUserSupportTitle' : 'boUnblockUserSupportTitle'}
        descriptionId={user.supportMessages ? 'boBlockUserSupportDescription' : 'boUnblockUserSupportDescription'}
        onRequestClose={() => closeModal('userSupport')}
        userSupport
      />

      <UserActionsModal
        user={user}
        isOpen={modals.userLpa}
        captionId={isUserLpa ? 'boRevokeUserLpaTitle' : 'boAppointUserLpaTitle'}
        descriptionId={isUserLpa ? 'boRevokeUserLpaDescription' : 'boAppointUserLpaDescription'}
        onRequestClose={() => closeModal('userLpa')}
        hasLpaEditPerm
      />

      <UserActionsModal
        user={user}
        isOpen={modals.userPartner}
        captionId="boDisablePartnershipTitle"
        descriptionId="boDisablePartnershipDescription"
        onRequestClose={() => closeModal('userPartner')}
        partnerBlock
      />

      <BasicModal
        captionId="authChangePassword"
        isOpen={modals.userPasswordChange}
        onRequestClose={() => closeModal('userPasswordChange')}>
        {modals.userPasswordChange && (
          <PasswordChangeForm
            id={match.params.id}
            changePasswordFunc={changeUserPassword}
            onSubmitSuccess={() => closeModal('userPasswordChange')}
            customRegex={customPasswordRegex}
            customLengthOptions={customPasswordLengthOptions}
          />
        )}
      </BasicModal>

      <BasicModal
        captionId="editPartnerCode"
        isOpen={modals.editPartnerCode}
        onRequestClose={() => closeModal('editPartnerCode')}>
        {modals.editPartnerCode && (
          <EditUserPartnerCodeForm onSubmitSuccess={() => closeModal('editPartnerCode')} user={user} />
        )}
      </BasicModal>

      <BasicModal
        captionId="confirmText"
        isOpen={modals.removePartnerCode}
        onRequestClose={() => closeModal('removePartnerCode')}>
        {modals.removePartnerCode && (
          <RemoveUserPatnerCodeForm
            onSubmitSuccess={() => closeModal('removePartnerCode')}
            closeModal={() => closeModal('removePartnerCode')}
            user={user}
          />
        )}
      </BasicModal>

      <BasicModal captionId="editManager" isOpen={modals.editManager} onRequestClose={() => closeModal('editManager')}>
        {modals.editManager && <EditUserManagerForm onSubmitSuccess={() => closeModal('editManager')} user={user} />}
      </BasicModal>

      <BasicModal captionId="editStatus" isOpen={modals.editStatus} onRequestClose={() => closeModal('editStatus')}>
        {modals.editStatus && <EditStatusForm onSubmitSuccess={() => closeModal('editStatus')} user={user} />}
      </BasicModal>

      <BasicModal
        captionId="justEditClientType"
        isOpen={modals.editClientType}
        onRequestClose={() => {
          closeModal('editClientType');
          setClientType('');
        }}>
        {modals.editClientType && (
          <EditClientTypeForm onSubmitSuccess={() => closeModal('editClientType')} user={user} />
        )}
      </BasicModal>

      <BasicModal
        isOpen={modals.rejectPartner}
        captionId="createRejectNotification"
        onRequestClose={() => closeModal('rejectPartner')}>
        {modals.rejectPartner && (
          <RejectPartnerForm id={match.params.id} onSubmitSuccess={() => closeModal('rejectPartner')} />
        )}
      </BasicModal>
    </PageContentPreloader>
  );
};

UserInfo.propTypes = {
  profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  userIsFullyLoaded: PropTypes.bool.isRequired,
  userIsLoaded: PropTypes.bool.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  getUserSumSubDocuments: PropTypes.func.isRequired,
  downloadUserSumSubDocument: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  isSumSubEnabled: PropTypes.bool.isRequired,
  states: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  verifyPartner: PropTypes.func.isRequired,
  confirmUserEmail: PropTypes.func.isRequired,
  userDocuments: PropTypes.object,
  documentsUploadErrors: PropTypes.object.isRequired,
  passport: PropTypes.object.isRequired,
  identificationProof: PropTypes.object.isRequired,
  addressProof: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  addons: PropTypes.array.isRequired,
  kycSettings: PropTypes.object.isRequired,
  userSumSubDocuments: PropTypes.array,
  userSumSubDocumentsAreLoaded: PropTypes.bool,
  theme: PropTypes.object,
};

UserInfo.defaultProps = {
  theme: {},
  states: [],
  userSumSubDocuments: [],
  userSumSubDocumentsAreLoaded: false,
  userDocuments: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      profile: state.user.profile,
      user: state.backoffice.users.user,
      countries: state.client.kyc.countries,
      states: state.client.kyc.states,
      userIsFullyLoaded: state.backoffice.users.userIsFullyLoaded,
      userIsLoaded: state.backoffice.users.userIsLoaded,
      userDocuments: state.backoffice.users.userDocuments,
      userDocumentsAreLoaded: state.backoffice.users.userDocumentsAreLoaded,
      userSumSubDocuments: state.backoffice.users.userSumSubDocuments,
      userSumSubDocumentsAreLoaded: state.backoffice.users.userSumSubDocumentsAreLoaded,
      documentsUploadErrors: state.backoffice.users.documentsUploadErrors,
      kycSettings: state.interfaceConfig.kycSettings,
      isSumSubEnabled: state.interfaceConfig.kycSettings.kyc_verifier === 'sumsub',
      passport: state.interfaceConfig.kycSettings.passport || {},
      identificationProof: state.interfaceConfig.kycSettings.identification_proof || {},
      addressProof: state.interfaceConfig.kycSettings.address_proof || {},
      lpaInfo: state.backoffice.users.lpaInfo,
      lpaInfoIsLoaded: state.backoffice.users.lpaInfoIsLoaded,
      addons: state.interfaceConfig.addons,
      modals: state.user.modals,
    }),
    {
      getUserInfo: id => getUserInfo.request(id),
      getUserSumSubDocuments: id => getUserSumSubDocuments.request(id),
      downloadUserSumSubDocument: payload => downloadUserSumSubDocument.request(payload),
      verifyPartner: id => verifyPartner.request(id),
      confirmUserEmail: data => confirmUserEmail.request(data),
      openModal: name => openModal(name),
      closeModal: name => closeModal(name),
      getStates: gid => getStates.request(gid),
      getLpaInfo: id => getLpaInfo.request(id),
      getCurrencies: () => getCurrencies.request(),
      setClientType: type => setClientType.request(type),
      requestEmailConfirmation: data => requestEmailConfirmation.request(data),
    }
  )
)(UserInfo);

export { UserInfo };
