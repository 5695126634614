import { all, put, call, fork, take, takeEvery, select, takeLatest, delay } from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import { SubmissionError } from 'redux-form';
import moment from 'moment';
import { sendYandexMetrikaEvent } from 'common/utils';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { closeModal } from 'interface/user/_redux/actions';

import { getDepositSaga, getDepositProofSaga } from 'backoffice/payments/_redux/sagas';
import * as api from '../api';
import * as ActionTypes from './actions';
import { setRedirect } from '../../_redux/actions';

export function* getCurrenciesSaga() {
  try {
    const { status, data } = yield call(api.getCurrencies);
    if (status < 300) {
      yield put(ActionTypes.getCurrencies.success(data));
    } else {
      yield put(ActionTypes.getCurrencies.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCurrencies.failure());
  }
}

export function* getCurrenciesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CURRENCIES[REQUEST]);
    yield call(getCurrenciesSaga);
  }
}

export function* getCountriesSaga() {
  try {
    const { status, data } = yield call(api.getCountries);
    if (status < 300) {
      yield put(ActionTypes.getCountries.success(data));
    } else {
      yield put(ActionTypes.getCountries.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCountries.failure());
  }
}

export function* getCountriesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_COUNTRIES[REQUEST]);
    yield call(getCountriesSaga);
  }
}

export function* getGeonameByGIDSaga(gid, type) {
  if (!gid) {
    yield put(
      ActionTypes.getGeonameByGID.success({
        [type]: '',
      })
    );
  } else {
    try {
      const { status, data } = yield call(api.getGeonameByGID, gid);
      if (status < 300) {
        const res = {
          [type]: data.name,
        };
        yield put(ActionTypes.getGeonameByGID.success(res));
      } else {
        yield put(ActionTypes.getGeonameByGID.failure(data.detail));
      }
    } catch (e) {
      yield put(acceptError(e));
      yield put(ActionTypes.getGeonameByGID.failure());
      throw e;
    }
  }
}

export function* getGeonameByGIDWatcherSaga() {
  yield takeEvery(ActionTypes.GET_GEONAME_BY_GID[REQUEST], getGeonameByGIDSaga);
}

export function* getUsersSaga({ search }) {
  try {
    yield delay(300);
    const { status, data } = yield call(api.getUsers, search);
    if (status < 300) {
      yield put(ActionTypes.getUsers.success(data));
      return { usersAreLoaded: true, data };
    }
    yield put(ActionTypes.getUsers.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUsers.failure());
    throw e;
  }
}

export function* getUsersWatcherSaga() {
  yield takeLatest(ActionTypes.GET_USERS[REQUEST], getUsersSaga);
}

export function* getUserDataSaga(id) {
  try {
    const { status, data } = yield call(api.getUserData, id);
    if (status < 300) {
      yield put(ActionTypes.getUserData.success(data));
      return { userIsReceived: true, data };
    }
    yield put(ActionTypes.getUserData.failure(status));
    yield put(setRedirect());
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserData.failure());
    throw e;
  }
}

export function* getUserDataWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_USER_DATA[REQUEST]);
    yield call(getUserDataSaga, id);
  }
}
export function* getUserLoginHistorySaga(id, params) {
  const { pageSize, currentPage, firstMonth, secondMonth } = params;
  try {
    const { status, data } = yield call(api.getUserLoginHistory, id, pageSize, currentPage, firstMonth, secondMonth);
    if (status < 300) {
      yield put(ActionTypes.getUserLoginHistory.success(data));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserLoginHistory.failure());
    throw e;
  }
}

export function* getUserLoginHistoryWatcherSaga() {
  while (true) {
    const { id, params } = yield take(ActionTypes.GET_USER_LOGIN_HISTORY[REQUEST]);
    yield call(getUserLoginHistorySaga, id, params);
  }
}

export function* getUserOperationHistorySaga(id, params) {
  const { pageSize, currentPage, firstMonth, secondMonth } = params;
  try {
    const { status, data } = yield call(
      api.getUserOperationHistory,
      id,
      pageSize,
      currentPage,
      firstMonth,
      secondMonth
    );
    if (status < 300) {
      yield put(ActionTypes.getUserOperationHistory.success(data));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserOperationHistory.failure());
    throw e;
  }
}

export function* getUserOperationHistoryWatcherSaga() {
  while (true) {
    const { id, params } = yield take(ActionTypes.GET_USER_OPERATION_HISTORY[REQUEST]);
    yield call(getUserOperationHistorySaga, id, params);
  }
}

export function* getUserDocumentFileSaga({ id, type, documentId }) {
  try {
    const { data } = yield call(api.getUserDocumentFile, id, documentId);
    const res = {
      type,
      documentId,
      file: data,
    };
    yield put(ActionTypes.getUserDocumentFile.success(res));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserDocumentFile.failure());
    throw e;
  }
}

export function* getUserDocumentFileWatcherSaga() {
  yield takeEvery(ActionTypes.GET_USER_DOCUMENT_FILE[REQUEST], getUserDocumentFileSaga);
}

export function* getUserDocumentListSaga(id) {
  try {
    const { data } = yield call(api.getUserDocumentList, id);
    yield put(ActionTypes.getUserDocumentList.success(data));
    return data;
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserDocumentList.failure());
    throw e;
  }
}

export function* getUserDocumentListWatcherSaga() {
  yield takeEvery(ActionTypes.GET_USER_DOCUMENT_LIST[REQUEST], getUserDocumentListSaga);
}

export function* getUserInfoSaga({ id }) {
  try {
    const { userIsReceived, data } = yield call(getUserDataSaga, id);

    if (userIsReceived && data.dataKyc) {
      if (data.dataKyc.address) {
        yield call(getGeonameByGIDSaga, data.dataKyc.address.country, 'country');
        yield call(getGeonameByGIDSaga, data.dataKyc.address.state, 'state');
      }
      yield call(getGeonameByGIDSaga, data.dataKyc.nationality, 'nationality');
      yield call(getGeonameByGIDSaga, data.dataKyc.taxResidence, 'taxResidence');

      const viewUserDocuments = yield select(state => state.user.profile.perms.viewUserDocuments);
      if (viewUserDocuments) {
        const uploadedDocumentStatuses = ['unverified', 'verified'];

        const documentList = yield call(getUserDocumentListSaga, id);

        const docs = Object.keys(documentList).reduce((arrayOfDocs, currentDocument) => {
          documentList[currentDocument].forEach(document => {
            arrayOfDocs.push(document);
          });
          return arrayOfDocs;
        }, []);

        yield all(
          docs.map(({ id: documentId, type, status }) => {
            if (uploadedDocumentStatuses.includes(status)) {
              return call(getUserDocumentFileSaga, { id, type, documentId });
            }
            return '';
          })
        );
      }
      yield put(ActionTypes.getUserInfo.success());
    } else {
      yield put(ActionTypes.getUserInfo.failure());
    }
  } catch (e) {
    yield put(ActionTypes.getUserInfo.failure());
    throw e;
  }
}

export function* getUserInfoWatcherSaga() {
  yield takeEvery(ActionTypes.GET_USER_INFO[REQUEST], getUserInfoSaga);
}

export function* verifyUserSaga(id) {
  try {
    const { status, data } = yield call(api.verifyUser, id);
    if (status < 300) {
      yield put(ActionTypes.verifyUser.success(data));
      yield call(getUserInfoSaga, { id });
      yield put(closeModal('userVerify'));
    } else {
      yield put(ActionTypes.verifyUser.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.verifyUser.failure());
    throw e;
  }
}

export function* verifyUserWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.VERIFY_USER[REQUEST]);
    yield call(verifyUserSaga, id);
  }
}

export function* blockUserSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { status, data } = yield call(api.blockUser, id, values);
    if (status < 300) {
      yield put(ActionTypes.blockUser.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.blockUser.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.blockUser.failure());
  }
}

export function* blockUserWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.blockUser.REQUEST);
    yield call(blockUserSaga, payload);
  }
}

export function* unblockUserSaga({ id }) {
  try {
    yield call(api.unblockUser, id);
    yield put(ActionTypes.unblockUser.success());
    yield put(closeModal('userBlock'));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.unblockUser.failure());
  }
}

export function* unblockUserWatcherSaga() {
  yield takeEvery(ActionTypes.UNBLOCK_USER[REQUEST], unblockUserSaga);
}

export function* disableSupportMessagesSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { status, data } = yield call(api.disableSupportMessages, id, values);
    if (status < 300) {
      yield put(ActionTypes.disableSupportMessages.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.disableSupportMessages.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.disableSupportMessages.failure());
  }
}

export function* disableSupportMessagesWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.disableSupportMessages.REQUEST);
    yield call(disableSupportMessagesSaga, payload);
  }
}

export function* enableSupportMessagesSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { status, data } = yield call(api.enableSupportMessages, id, values);
    if (status < 300) {
      yield put(ActionTypes.enableSupportMessages.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.enableSupportMessages.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.enableSupportMessages.failure());
  }
}

export function* enableSupportMessagesWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.enableSupportMessages.REQUEST);
    yield call(enableSupportMessagesSaga, payload);
  }
}

export function* verifyUserDocumentSaga({ id, documentId }) {
  try {
    yield call(api.verifyUserDocument, id, documentId);
    yield put(ActionTypes.verifyUserDocument.success(documentId));
    yield call(getUserInfoSaga, { id });
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.verifyUserDocument.failure(documentId));
    throw e;
  }
}

export function* verifyUserDocumentWatcherSaga() {
  yield takeEvery(ActionTypes.VERIFY_USER_DOCUMENT[REQUEST], verifyUserDocumentSaga);
}

export function* rejectUserDocumentSaga({ id, documentId, ...values }) {
  try {
    yield call(api.rejectUserDocument, id, documentId, values);
    yield put(ActionTypes.rejectUserDocument.success(documentId));
    yield call(getUserInfoSaga, { id });
    yield put(closeModal(documentId));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectUserDocument.failure(documentId));
    throw e;
  }
}

export function* rejectUserDocumentWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectUserDocument.REQUEST);
    yield call(rejectUserDocumentSaga, payload);
  }
}

export function* uploadUserDocumentSaga(id, file, isDeposit) {
  try {
    const { status, data } = yield call(api.uploadUserDocument, id, file, isDeposit);
    if (status < 300) {
      yield put(ActionTypes.uploadUserDocument.success(file));
      if (isDeposit) {
        yield call(getDepositSaga, id);
      }
      yield call(getDepositProofSaga, id);
    } else {
      yield put(ActionTypes.uploadUserDocument.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.uploadUserDocument.failure());
  }
}

export function* uploadUserDocumentWatcherSaga() {
  while (true) {
    const {
      data: { id, file, isDeposit },
    } = yield take(ActionTypes.UPLOAD_USER_DOCUMENT[REQUEST]);
    yield call(uploadUserDocumentSaga, id, file, isDeposit);
  }
}

export function* withdrawalProofSaga(id) {
  try {
    const { data, status } = yield call(api.getWithdrawalProof, id);
    if (status < 300) {
      yield put(ActionTypes.getWithdrawalProof.success(data));
    }
  } catch (e) {
    yield put(ActionTypes.getWithdrawalProof.failure(e));
  }
}

export function* withdrawalProofWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_WITHDRAWAL_PROOF[REQUEST]);
    yield call(withdrawalProofSaga, id);
  }
}

export function* verifyPartnerSaga(id) {
  try {
    const { status, data } = yield call(api.verifyPartner, id);
    if (status < 300) {
      yield put(ActionTypes.verifyPartner.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.verifyPartner.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.verifyPartner.failure());
    throw e;
  }
}

export function* verifyPartnerWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.VERIFY_PARTNER[REQUEST]);
    yield call(verifyPartnerSaga, id);
  }
}

export function* rejectPartnerSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { status, data } = yield call(api.rejectPartner, id, values);
    if (status < 300) {
      yield put(ActionTypes.rejectPartner.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.rejectPartner.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectPartner.failure());
    throw e;
  }
}

export function* rejectPartnerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectPartner.REQUEST);
    yield call(rejectPartnerSaga, payload);
  }
}

export function* removePhoneSaga(id) {
  try {
    const { status, data } = yield call(api.removeUserPhone, id);
    if (status < 300) {
      yield put(ActionTypes.removePhone.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.removePhone.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.removePhone.failure());
    throw e;
  }
}

export function* removePhoneWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.removePhone.REQUEST);
    yield call(removePhoneSaga, payload);
  }
}

export function* editUserInfoSaga(payload) {
  const userId = payload.userId;
  const fieldName = Object.keys(payload)[0];
  try {
    const { status, data } = yield call(api.editUserInfo, userId, fieldName, payload[fieldName]);
    if (status < 300) {
      yield put(ActionTypes.editUserInfo.success());
      yield call(getUserInfoSaga, { id: userId });
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.editUserInfo.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editUserInfo.failure());
  }
}

export function* editUserInfoWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editUserInfo.REQUEST);
    yield call(editUserInfoSaga, payload);
  }
}

export function* getUserAccountSaga(userId, accountId) {
  try {
    const { status, data } = yield call(api.getUserAccount, userId, accountId);
    if (status < 300) {
      yield put(ActionTypes.getUserAccount.success(data));
    } else {
      yield put(ActionTypes.getUserAccount.failure(accountId, data.detail));
      yield put(setRedirect());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserAccount.failure(accountId, e));
  }
}

export function* getUserAccountWatcherSaga() {
  while (true) {
    const { userId, accountId } = yield take(ActionTypes.GET_USER_ACCOUNT[REQUEST]);
    yield call(getUserAccountSaga, userId, accountId);
  }
}

export function* getUserAccountsPrefetchSaga({ userId, type, ...params }) {
  try {
    const { status, data } = yield call(api.getUserAccountsPrefetch, userId, params);
    if (status < 300) {
      yield put(ActionTypes.getUserAccountsPrefetch.success(data));
    } else {
      yield put(ActionTypes.getUserAccountsPrefetch.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserAccountsPrefetch.failure());
  }
}

export function* getUserAccountsSaga({ userId, noDetails, type, ...params }) {
  try {
    const { status, data } = yield call(api.getUserAccounts, userId, params);
    if (status < 300) {
      yield put(ActionTypes.getUserAccounts.success(data));
      if (!noDetails) {
        yield call(getUserAccountsPrefetchSaga, { userId, ...params });
      }
    } else {
      yield put(ActionTypes.getUserAccounts.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserAccounts.failure());
  }
}

export function* getUserAccountsWatcherSaga() {
  yield takeEvery(ActionTypes.GET_USER_ACCOUNTS[REQUEST], getUserAccountsSaga);
}

export function* blockUserAccountSaga({ userId, accountId }) {
  try {
    yield call(api.blockUserAccount, userId, accountId);
    yield put(ActionTypes.blockUserAccount.success(accountId));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.blockUserAccount.failure(accountId));
  }
}

export function* blockUserAccountWatcherSaga() {
  yield takeEvery(ActionTypes.BLOCK_USER_ACCOUNT[REQUEST], blockUserAccountSaga);
}

export function* unblockUserAccountSaga({ userId, accountId }) {
  try {
    yield call(api.unblockUserAccount, userId, accountId);
    yield put(ActionTypes.unblockUserAccount.success(accountId));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.unblockUserAccount.failure(accountId));
  }
}

export function* unblockUserAccountWatcherSaga() {
  yield takeEvery(ActionTypes.UNBLOCK_USER_ACCOUNT[REQUEST], unblockUserAccountSaga);
}

export function* deleteUserAccountSaga({ userId, accountId }) {
  try {
    yield call(api.deleteUserAccount, userId, accountId);
    yield put(ActionTypes.deleteUserAccount.success(accountId));
    yield call(getUserAccountsSaga, { userId });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteUserAccount.failure(accountId));
  }
}

export function* deleteUserAccountWatcherSaga() {
  yield takeEvery(ActionTypes.DELETE_USER_ACCOUNT[REQUEST], deleteUserAccountSaga);
}

export function* restoreUserAccountSaga({ userId, accountId }) {
  try {
    yield call(api.restoreUserAccount, userId, accountId);
    yield put(ActionTypes.restoreUserAccount.success(accountId));
    yield call(getUserAccountsSaga, { userId });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.restoreUserAccount.failure(accountId));
  }
}

export function* restoreUserAccountWatcherSaga() {
  yield takeEvery(ActionTypes.RESTORE_USER_ACCOUNT[REQUEST], restoreUserAccountSaga);
}

export function* getUserTotalPaymentsSaga(id) {
  try {
    const { data, status } = yield call(api.getUserTotalPayments, id);

    if (status < 300) {
      yield put(ActionTypes.getUserTotalPayments.success(data));
    } else {
      yield put(ActionTypes.getUserTotalPayments.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserTotalPayments.failure());
  }
}

export function* getUserTotalPaymentsWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_USER_TOTAL_PAYMENTS[REQUEST]);
    yield call(getUserTotalPaymentsSaga, id);
  }
}

export function* getUserPaymentsSaga({ id, search }) {
  try {
    const { data } = yield call(api.getUserPayments, id, search);
    yield put(ActionTypes.getUserPayments.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserPayments.failure());
  }
}

export function* getUserPaymentsWatcherSaga() {
  while (true) {
    const { id, search } = yield take(ActionTypes.GET_USER_PAYMENTS[REQUEST]);
    yield call(getUserPaymentsSaga, id, search);
  }
}

export function* getUserInternalTransfersSaga({ userId, search }) {
  try {
    const { status, data } = yield call(api.getUserInternalTransfers, userId, search);
    if (status < 300) {
      yield put(ActionTypes.getUserInternalTransfers.success(data));
    } else {
      yield put(ActionTypes.getUserInternalTransfers.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserInternalTransfers.failure());
  }
}

export function* getUserInternalTransfersWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_USER_INTERNAL_TRANSFERS[REQUEST]);
    yield call(getUserInternalTransfersSaga, data);
  }
}

export function* getUserBonusSaga({ id, search }) {
  try {
    const { data } = yield call(api.getUserBonus, id, search);
    yield put(ActionTypes.getUserBonus.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserBonus.failure());
  }
}

export function* getUserBonusWatcherSaga() {
  while (true) {
    const { id, search } = yield take(ActionTypes.GET_USER_BONUS[REQUEST]);
    yield call(getUserBonusSaga, id, search);
  }
}

export function* createUserBonusSaga({ userId, validUntil, convRate, conversion, isEndless, ...formValues }) {
  const payload = {
    ...formValues,
    validUntil: !isEndless ? moment(formValues.validUntil).format('YYYY-MM-DD') : undefined,
    convRate: conversion ? convRate : undefined,
  };
  try {
    yield call(api.createUserBonus, userId, payload);
    yield put(ActionTypes.createUserBonus.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createUserBonus.failure());
  }
}

export function* createUserBonusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createUserBonus.REQUEST);
    yield call(createUserBonusSaga, payload);
  }
}

export function* withdrawUserBonusBonusSaga({ userId, bonusId, chargeValue, chargeAll, approved }) {
  try {
    const { status, data } = yield call(api.withdrawUserBonus, userId, bonusId, {
      chargeValue,
      chargeAll,
      approved,
    });

    if (status < 300) {
      yield put(ActionTypes.withdrawUserBonus.success());
    } else if (status === 409 && data.reason === 'approve_required') {
      yield put(ActionTypes.showWithdrawApproveModal());
      yield put(ActionTypes.withdrawUserBonus.failure(data));
    } else {
      yield put(ActionTypes.withdrawUserBonus.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.withdrawUserBonus.failure());
  }
}

export function* withdrawUserBonusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.withdrawUserBonus.REQUEST);
    yield call(withdrawUserBonusBonusSaga, payload);
  }
}

export function* activateUserBonusSaga({ userId, bonusId, isEndless, conversion, ...formValues }) {
  try {
    let payload = { ...formValues };
    if (formValues.validUntil || isEndless) {
      payload = {
        ...payload,
        validUntil: isEndless ? '9999-01-01' : moment(formValues.validUntil).format('YYYY-MM-DD'),
      };
    }
    if (!conversion && !formValues.simple) {
      payload = { ...payload, convRate: 0 };
    }
    if (formValues.date) {
      payload = { ...payload, date: moment(formValues.date).format('x') };
    }
    const { status, data } = yield call(api.activateUserBonus, userId, bonusId, payload);
    if (status < 300) {
      yield put(ActionTypes.activateUserBonus.success());
      const EVENT_NAME = 'Bonus';
      sendYandexMetrikaEvent(EVENT_NAME);
    } else {
      yield put(ActionTypes.activateUserBonus.failure(new SubmissionError({ _error: data.details })));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.activateUserBonus.failure());
  }
}

export function* activateUserBonusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.activateUserBonus.REQUEST);
    yield call(activateUserBonusSaga, payload);
  }
}

export function* getUserNotesSaga(id) {
  try {
    const { status, data } = yield call(api.getUserNotes, id);
    if (status < 300) {
      yield put(ActionTypes.getUserNotes.success(data));
    } else {
      yield put(ActionTypes.getUserNotes.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserNotes.failure());
    throw e;
  }
}

export function* getUserNotesWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_USER_NOTES[REQUEST]);
    yield call(getUserNotesSaga, id);
  }
}

export function* createUserNoteSaga({ id, title, text, documentss, isPinned }) {
  // FIXME: НЕ ПРИНИМАЕТ ПРОСТО documents
  try {
    yield call(api.createUserNote, id, {
      documents: documentss,
      title,
      text,
      isPinned,
    });
    yield put(ActionTypes.createUserNote.success());
    yield call(getUserNotesSaga, id);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createUserNote.failure());
  }
}

export function* createUserNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createUserNote.REQUEST);
    yield call(createUserNoteSaga, payload);
  }
}

export function* changeUserPasswordSaga(id, password) {
  try {
    const { status, data } = yield call(api.changeUserPassword, id, password);
    if (status < 300) {
      yield put(ActionTypes.changeUserPassword.success());
      yield call(getUserInfoSaga, { id });
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.changeUserPassword.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.changeUserPassword.failure());
  }
}

export function* changeUserPasswordWatcherSaga() {
  while (true) {
    const {
      payload: { id, password },
    } = yield take(ActionTypes.changeUserPassword.REQUEST);
    yield call(changeUserPasswordSaga, id, password);
  }
}

function* getAllTagsSaga() {
  try {
    const { data } = yield call(api.getAllTags);
    yield put(ActionTypes.getAllTags.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAllTags.failure(e));
  }
}

function* getAllTagsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_ALL_TAGS[REQUEST]);
    yield call(getAllTagsSaga);
  }
}

function* getUserTagsSaga(id) {
  try {
    const { data } = yield call(api.getUserTags, id);
    yield put(ActionTypes.getUserTags.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserTags.failure(e));
  }
}

function* getUserTagsWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_USER_TAGS[REQUEST]);
    yield call(getUserTagsSaga, id);
  }
}

export function* setUserTagSaga(id, tags) {
  try {
    yield all(tags.map(tagId => call(api.setUserTag, id, tagId)));
    yield put(ActionTypes.setUserTag.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.setUserTag.failure());
  }
}

export function* setUserTagWatcherSaga() {
  while (true) {
    const {
      payload: { id, tags },
    } = yield take(ActionTypes.setUserTag.REQUEST);
    yield call(setUserTagSaga, id, tags);
  }
}

export function* deleteUserTagSaga(userId, tagId) {
  try {
    yield call(api.deleteUserTag, userId, tagId);
    yield put(ActionTypes.deleteUserTag.success());
    yield call(getUserTagsSaga, userId);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteUserTag.failure());
  }
}

export function* deleteUserTagWatcherSaga() {
  while (true) {
    const { userId, tagId } = yield take(ActionTypes.DELETE_USER_TAG[REQUEST]);
    yield call(deleteUserTagSaga, userId, tagId);
  }
}

export function* getUserPartnerAccountsSaga(userId) {
  try {
    const { status, data } = yield call(api.getUserPartnerAccounts, userId);
    if (status < 300) {
      yield put(ActionTypes.getUserPartnerAccounts.success(data));
    } else {
      yield put(ActionTypes.getUserPartnerAccounts.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserPartnerAccounts.failure());
    throw e;
  }
}

export function* getUserPartnerAccountsWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.GET_USER_PARTNER_ACCOUNTS[REQUEST]);
    yield call(getUserPartnerAccountsSaga, userId);
  }
}

function* getPartnershipConditionsListSaga(userId) {
  try {
    const { data } = yield call(api.getPartnershipConditionsList, userId);
    yield put(ActionTypes.getPartnershipConditionsList.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipConditionsList.failure(e));
  }
}

function* getPartnershipConditionsListWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.GET_PARTNERSHIP_CONDITIONS_LIST[REQUEST]);
    yield call(getPartnershipConditionsListSaga, userId);
  }
}

export function* createUserPartnerAccountSaga(payload) {
  try {
    const { status, data } = yield call(api.createUserPartnerAccount, payload);
    if (status < 300) {
      yield put(ActionTypes.createUserPartnerAccount.success());
    } else {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.createUserPartnerAccount.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createUserPartnerAccount.failure());
  }
}

export function* createUserPartnerAccountWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createUserPartnerAccount.REQUEST);
    yield call(createUserPartnerAccountSaga, payload);
  }
}

export function* editUserPartnerAccountSaga({ accountId, ...payload }) {
  try {
    const { status, data } = yield call(api.editUserPartnerAccount, accountId, payload);
    if (status < 300) {
      yield put(ActionTypes.editUserPartnerAccount.success());
    } else {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.editUserPartnerAccount.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editUserPartnerAccount.failure());
  }
}

export function* editUserPartnerAccountWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editUserPartnerAccount.REQUEST);
    yield call(editUserPartnerAccountSaga, payload);
  }
}

function* editUserCanActivateBonusesSaga({ userId, canActivateBonuses }) {
  try {
    const { data, status } = yield call(
      api.editUserInfo,
      userId,
      'disallow_bonuses',
      canActivateBonuses ? 'True' : 'False'
    );
    if (status < 300) {
      yield put(ActionTypes.editUserCanActivateBonuses.success({ disallowBonuses: canActivateBonuses }));
    } else {
      yield put(ActionTypes.editUserCanActivateBonuses.failure(data));
      throw new Error();
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editUserCanActivateBonuses.failure(e));
  }
}

function* editUserCanActivateBonusesWatcherSaga() {
  while (true) {
    const { userId, canActivateBonuses } = yield take(ActionTypes.EDIT_USER_CAN_ACTIVATE_BONUSES[REQUEST]);
    yield call(editUserCanActivateBonusesSaga, { userId, canActivateBonuses });
  }
}

function* getAvailablePartnerCodesSaga(userId, search) {
  try {
    const { data } = yield call(api.getAvailablePartnerCodes, userId, search);
    yield put(ActionTypes.getAvailablePartnerCodes.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAvailablePartnerCodes.failure(e));
  }
}

function* getAvailablePartnerCodesWatcherSaga() {
  while (true) {
    const { userId, search } = yield take(ActionTypes.GET_AVAILABLE_PARTNER_CODES[REQUEST]);
    yield call(getAvailablePartnerCodesSaga, userId, search);
  }
}

export function* editUserPartnerCodeSaga(payload) {
  try {
    const { userId, partnerAccount } = payload;
    yield call(api.editUserPartnerCode, userId, partnerAccount);
    yield put(ActionTypes.editUserPartnerCode.success());
    yield call(getUserInfoSaga, { id: userId });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editUserPartnerCode.failure());
  }
}

export function* editUserPartnerCodeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editUserPartnerCode.REQUEST);
    yield call(editUserPartnerCodeSaga, payload);
  }
}

export function* removeUserPartnerCodeSaga(payload) {
  try {
    const { userId } = payload;
    yield call(api.removeUserPartnerCode, userId);
    yield put(ActionTypes.removeUserPartnerCode.success());
    yield call(getUserInfoSaga, { id: userId });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.removeUserPartnerCode.failure());
  }
}

export function* removeUserPartnerCodeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.removeUserPartnerCode.REQUEST);
    yield call(removeUserPartnerCodeSaga, payload);
  }
}

export function* editTradeAccountPartnerCodeSaga(payload) {
  try {
    const { userId, tradeAccountId, partnerCode } = payload;
    yield call(api.editTradeAccountPartnerCode, userId, tradeAccountId, partnerCode);
    yield put(ActionTypes.editTradeAccountPartnerCode.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editTradeAccountPartnerCode.failure());
  }
}

export function* editTradeAccountPartnerCodeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editTradeAccountPartnerCode.REQUEST);
    yield call(editTradeAccountPartnerCodeSaga, payload);
  }
}

export function* removeTradeAccountUserPartnerCodeSaga(payload) {
  try {
    const { userId, tradeAccountId } = payload;

    yield call(api.removeTradeAccountUserPartnerCode, userId, tradeAccountId);
    yield put(ActionTypes.removeTradeAccountUserPartnerCode.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.removeTradeAccountUserPartnerCode.failure());
  }
}

export function* removeTradeAccountUserPartnerCodeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.removeTradeAccountUserPartnerCode.REQUEST);
    yield call(removeTradeAccountUserPartnerCodeSaga, payload);
  }
}

function* startImpersonateSaga({ userId }) {
  try {
    yield call(api.startImpersonate, userId);
    yield put(ActionTypes.startImpersonate.success());
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.startImpersonate.failure(e));
  }
}

function* startImpersonateWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.START_IMPERSONATE[REQUEST]);
    yield call(startImpersonateSaga, data);
  }
}

export function* rejectUserVerificationSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { data, status } = yield call(api.rejectUser, id, values);
    if (status < 300) {
      yield put(ActionTypes.rejectUserVerification.success());
      yield call(getUserInfoSaga, { id });
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.rejectUserVerification.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectUserVerification.failure());
    throw e;
  }
}

export function* rejectUserVerificationWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectUserVerification.REQUEST);
    yield call(rejectUserVerificationSaga, payload);
  }
}

function* getUserIssuesSaga(id) {
  try {
    const { data } = yield call(api.getUserIssues, id);
    yield put(ActionTypes.getUserIssues.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserIssues.failure(e));
  }
}

function* getUserIssuesWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_USER_ISSUES[REQUEST]);
    yield call(getUserIssuesSaga, id);
  }
}

export function* getUserCardsSaga(userId) {
  try {
    const { status, data } = yield call(api.getUserCards, userId);
    if (status < 300) {
      yield put(ActionTypes.getUserCards.success(data));
    } else {
      yield put(ActionTypes.getUserCards.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserCards.failure());
    throw e;
  }
}

export function* getUserCardsWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.GET_USER_CARDS[REQUEST]);
    yield call(getUserCardsSaga, userId);
  }
}

export function* getCardPhotoSaga({ userId, cardId, side }) {
  try {
    const { status, data } = yield call(api.getCardPhoto, userId, cardId, side);
    if (status < 300) {
      yield put(ActionTypes.getCardPhoto.success({ cardPhoto: data, cardId, side }));
    } else {
      yield put(ActionTypes.getCardPhoto.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCardPhoto.failure());
    throw e;
  }
}

export function* getCardPhotoWatcherSaga() {
  yield takeEvery(ActionTypes.GET_CARD_PHOTO[REQUEST], getCardPhotoSaga);
}

export function* verifyUserCardSaga({ userId, cardId, ...values }) {
  try {
    const { status, data } = yield call(api.verifyUserCard, userId, cardId, values);
    if (status < 300) {
      yield put(ActionTypes.verifyUserCard.success(data));
      yield call(getUserCardsSaga, userId);
    } else {
      yield put(ActionTypes.verifyUserCard.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.verifyUserCard.failure());
    throw e;
  }
}

export function* verifyUserCardWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.verifyUserCard[REQUEST]);
    yield call(verifyUserCardSaga, payload);
  }
}

export function* rejectUserCardSaga({ userId, cardId, ...values }) {
  try {
    const { status, data } = yield call(api.rejectUserCard, userId, cardId, values);
    if (status < 300) {
      yield put(ActionTypes.rejectUserCard.success(data));
      yield call(getUserCardsSaga, userId);
    } else {
      yield put(ActionTypes.rejectUserCard.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectUserCard.failure(cardId));
    throw e;
  }
}

export function* rejectUserCardWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectUserCard.REQUEST);
    yield call(rejectUserCardSaga, payload);
  }
}

export function* addUserCardSaga({ userId, formData }) {
  try {
    const { status, data } = yield call(api.addUserCard, userId, formData);
    if (status <= 300) {
      yield put(ActionTypes.addUserCard.success(data));
      yield call(getUserCardsSaga, userId);
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.addUserCard.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.addUserCard.failure());
  }
}

export function* addUserCardSagaWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.addUserCard.REQUEST);
    yield call(addUserCardSaga, payload);
  }
}

function* getUserAvailableDepositsSaga({ userId }) {
  try {
    const { data } = yield call(api.getUserAvailableDeposits, userId);
    yield put(ActionTypes.getUserAvailableDeposits.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserAvailableDeposits.failure(e));
  }
}

function* getUserAvailableDepositsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_USER_AVAILABLE_DEPOSITS[REQUEST]);
    yield call(getUserAvailableDepositsSaga, data);
  }
}

export function* confirmUserEmailSaga({ userId }) {
  try {
    const { status, data } = yield call(api.confirmUserEmail, userId);
    if (status < 300) {
      yield put(ActionTypes.confirmUserEmail.success(data));
      yield call(getUserInfoSaga, { id: userId });
    } else {
      yield put(ActionTypes.confirmUserEmail.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.confirmUserEmail.failure());
    throw e;
  }
}

export function* confirmUserEmailWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.CONFIRM_USER_EMAIL[REQUEST]);
    yield call(confirmUserEmailSaga, data);
  }
}

export function* cancelUserBonusSaga({ userId, bonusId, search }) {
  try {
    const { data, status } = yield call(api.cancelUserBonus, userId, bonusId);
    if (status < 300) {
      yield put(ActionTypes.cancelUserBonus.success(data));
      yield call(getUserBonusSaga, { id: userId, search });
    } else {
      yield put(ActionTypes.cancelUserBonus.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.cancelUserBonus.failure());
    throw e;
  }
}

export function* cancelUserBonusWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.CANCEL_USER_BONUS[REQUEST]);
    yield call(cancelUserBonusSaga, payload);
  }
}

export function* getUserUtmHistorySaga({ userId, search }) {
  try {
    const { status, data } = yield call(api.getUserUtmHistory, userId, search);
    if (status < 300) {
      yield put(ActionTypes.getUserUtmHistory.success(data));
    } else {
      yield put(ActionTypes.getUserUtmHistory.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserUtmHistory.failure());
    throw e;
  }
}

export function* getUserUtmHistoryWatcherSaga() {
  while (true) {
    const { userId, search } = yield take(ActionTypes.GET_USER_UTM_HISTORY[REQUEST]);
    yield call(getUserUtmHistorySaga, userId, search);
  }
}

export function* getFreeClientsCountSaga() {
  try {
    const { status, data } = yield call(api.getFreeClientsCount);
    if (status < 300) {
      yield put(ActionTypes.getFreeClientsCount.success(data));
    } else {
      yield put(ActionTypes.getFreeClientsCount.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getFreeClientsCount.failure());
    throw e;
  }
}

export function* getFreeClientsCountWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_FREE_CLIENTS_COUNT[REQUEST]);
    yield call(getFreeClientsCountSaga);
  }
}

export function* getManagerClientSaga({ search }) {
  try {
    const { status, data } = yield call(api.getManagerClient);
    if (status < 300) {
      yield put(ActionTypes.getManagerClient.success(data));
      yield call(getUsersSaga, { search });
      yield call(getFreeClientsCountSaga);
    } else {
      yield put(ActionTypes.getManagerClient.failure(data.error));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getManagerClient.failure());
    throw e;
  }
}

export function* getManagerClientWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_MANAGER_CLIENT[REQUEST]);
    yield call(getManagerClientSaga, data);
  }
}

export function* getBackofficeManagerListSaga(params) {
  try {
    const { status, data } = yield call(api.getBackofficeManagerList, params);
    if (status < 300) {
      yield put(ActionTypes.getBackofficeManagerList.success(data));
    } else {
      yield put(ActionTypes.getBackofficeManagerList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getBackofficeManagerList.failure());
    throw e;
  }
}

export function* getBackofficeManagerListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_BACKOFFICE_MANAGER_LIST[REQUEST]);
    yield call(getBackofficeManagerListSaga, data);
  }
}

export function* setUserManagerSaga(payload) {
  try {
    const { userId, manager } = payload;
    yield call(api.setUserManager, userId, manager);
    yield put(ActionTypes.setUserManager.success());
    yield call(getUserInfoSaga, { id: userId });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.setUserManager.failure());
  }
}

export function* setUserManagerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.setUserManager.REQUEST);
    yield call(setUserManagerSaga, payload);
  }
}

export function* editUserStatusSaga({ userId, statusId }) {
  try {
    const { status, data } = yield call(api.editUserStatus, { userId, statusId });
    if (status < 300) {
      yield put(ActionTypes.editUserStatus.success());
      yield call(getUserInfoSaga, { id: userId });
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.editUserStatus.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editUserStatus.failure());
  }
}

export function* editUserStatusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editUserStatus.REQUEST);
    yield call(editUserStatusSaga, payload);
  }
}

export function* createDocumentSaga({ id, body }) {
  try {
    const { status, data } = yield call(api.createDocument, id, body);
    if (status < 300) {
      yield put(ActionTypes.createDocument.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.createDocument.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createDocument.failure(e));
  }
}

export function* createDocumentWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createDocument.REQUEST);
    yield call(createDocumentSaga, payload);
  }
}

export function* getUserNoteDocumentSaga({ userId, documentId }) {
  try {
    const { data, headers } = yield call(api.getUserNoteDocument, userId, documentId);
    const fileName = headers['content-disposition'].replace('attachment; filename=', '').replaceAll('"', '');
    FileDownload(data, fileName);
    yield put(ActionTypes.getUserNoteDocument.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserNoteDocument.failure());
  }
}

export function* getUserNoteDocumentWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_USER_NOTE_DOCUMENT[REQUEST]);
    yield call(getUserNoteDocumentSaga, data);
  }
}

export function* deleteUserNoteSaga({ userId, noteId }) {
  try {
    const { status, data } = yield call(api.deleteUserNote, userId, noteId);
    if (status < 300) {
      yield put(ActionTypes.deleteUserNote.success(data));
      yield call(getUserNotesSaga, userId);
    } else {
      yield put(ActionTypes.deleteUserNote.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteUserNote.failure());
  }
}

export function* deleteUserNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.deleteUserNote[REQUEST]);
    yield call(deleteUserNoteSaga, payload);
  }
}

export function* editUserNoteSaga({ userId, noteId, title, text, documentss, isPinned }) {
  // FIXME: НЕ ПРИНИМАЕТ ПРОСТО documents
  try {
    yield call(api.editUserNote, userId, noteId, {
      title,
      text,
      documents: documentss,
      isPinned,
    });
    yield put(ActionTypes.editUserNote.success());
    yield call(getUserNotesSaga, userId);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editUserNote.failure());
  }
}

export function* editUserNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editUserNote.REQUEST);
    yield call(editUserNoteSaga, payload);
  }
}

export function* removeUserNoteDocumentSaga({ userId, documentId }) {
  try {
    const { status, data } = yield call(api.removeUserNoteDocument, userId, documentId);
    if (status < 300) {
      yield put(ActionTypes.removeUserNoteDocument.success(data));
      yield call(getUserNotesSaga, userId);
    } else {
      yield put(ActionTypes.removeUserNoteDocument.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.removeUserNoteDocument.failure());
  }
}

export function* removeUserNoteDocumentWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.REMOVE_USER_NOTE_DOCUMENT[REQUEST]);
    yield call(removeUserNoteDocumentSaga, data);
  }
}

export function* getUserKYCSaga({ id }) {
  try {
    const { data } = yield call(api.getUserKYC, id);
    yield put(ActionTypes.getUserKYC.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserKYC.failure());
  }
}

export function* getUserKYCWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_USER_KYC[REQUEST]);
    yield call(getUserKYCSaga, data);
  }
}

export function* editUserKYCSaga({ id, ...values }) {
  try {
    const sections = ['forex', 'derivative', 'securities', 'investmentFund'];
    sections.forEach(section => {
      // if section was clicked but not filled
      if (values.experience[section] && !values.experience[section].checkbox) {
        // set section to null
        values.experience[section] = null;
      }
    });

    const { status, data } = yield call(api.editUserKYC, id, values);

    if (status < 300) {
      yield put(ActionTypes.editUserKYC.success());
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.editUserKYC.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editUserKYC.failure());
  }
}

export function* editUserKYCWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editUserKYC.REQUEST);
    yield call(editUserKYCSaga, payload);
  }
}

export function* getTrederoCustomUserKYCSaga({ id }) {
  try {
    const { data } = yield call(api.getUserKYC, id);
    yield put(ActionTypes.getTrederoCustomUserKYC.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getTrederoCustomUserKYC.failure());
  }
}

export function* getTrederoCustomUserKYCWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_TREDERO_CUSTOM_USER_KYC[REQUEST]);
    yield call(getTrederoCustomUserKYCSaga, data);
  }
}

export function* editTrederoCustomUserKYCSaga({ id, ...values }) {
  try {
    const sections = ['forex', 'derivative', 'securities', 'investmentFund'];
    sections.forEach(section => {
      // if section was clicked but not filled
      if (values.experience[section] && !values.experience[section].checkbox) {
        // set section to null
        values.experience[section] = null;
      }
    });

    const { status, data } = yield call(api.editUserKYC, id, values);

    if (status < 300) {
      yield put(ActionTypes.editTrederoCustomUserKYC.success());
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.editTrederoCustomUserKYC.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editTrederoCustomUserKYC.failure());
  }
}

export function* editTrederoCustomUserKYCWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editTrederoCustomUserKYC.REQUEST);
    yield call(editTrederoCustomUserKYCSaga, payload);
  }
}

export function* createUserSaga(payload) {
  const { email, firstName, lastName, phone } = payload;

  try {
    const { status, data } = yield call(api.createUser, email.toLowerCase(), firstName, lastName, phone);

    if (status < 300) yield put(ActionTypes.createUser.success());
    else yield put(ActionTypes.createUser.failure(new SubmissionError(data)));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createUser.failure());
  }
}

export function* createUserWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createUser.REQUEST);
    yield call(createUserSaga, payload);
  }
}

export function* getServerListSaga() {
  try {
    const { data } = yield call(api.getServerList);

    yield put(ActionTypes.getServerList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getServerList.failure(e));
  }
}

export function* getServerListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_SERVER_LIST[REQUEST]);
    yield call(getServerListSaga);
  }
}

export function* createUserAccountSaga(payload) {
  const { userId, login, server } = payload;

  try {
    const { status, data } = yield call(api.createUserAccount, userId, login, server);

    if (status < 300) yield put(ActionTypes.createUserAccount.success());
    else yield put(ActionTypes.createUserAccount.failure(new SubmissionError(data)));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createUserAccount.failure());
  }
}

export function* createUserAccountWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createUserAccount.REQUEST);
    yield call(createUserAccountSaga, payload);
  }
}

export function* getCallProvidersListSaga() {
  try {
    const { data } = yield call(api.getCallProvidersList);

    yield put(ActionTypes.getCallProvidersList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCallProvidersList.failure(e));
  }
}

export function* getCallProvidersListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CALL_PROVIDERS_LIST[REQUEST]);
    yield call(getCallProvidersListSaga);
  }
}

export function* makeACallSaga(payload) {
  const { id, provider } = payload;
  try {
    const { status, data } = yield call(api.makeACall, id, provider);
    if (status < 300) yield put(ActionTypes.makeACall.success());
    else yield put(ActionTypes.makeACall.failure(new SubmissionError(data)));
  } catch (e) {
    const error = e.response.data.detail ? e.response.data.detail : e;
    yield put(acceptError(error, true));
    yield put(ActionTypes.makeACall.failure());
  }
}

export function* makeACallWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.MAKE_A_CALL[REQUEST]);
    yield call(makeACallSaga, data);
  }
}

function* getLpaInfoSaga({ id }) {
  try {
    const { status, data } = yield call(api.getLpaInfo, id);

    if (status < 300) {
      yield put(ActionTypes.getLpaInfo.success(data));
    } else {
      yield put(ActionTypes.getLpaInfo.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getLpaInfo.failure(e));
  }
}

function* getLpaInfoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_LPA_INFO[REQUEST]);
    yield call(getLpaInfoSaga, data);
  }
}

export function* editLpaStatusSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { status, data } = yield call(api.editLpaStatus, id, values);
    if (status < 300) {
      yield put(ActionTypes.editLpaStatus.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.editLpaStatus.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editLpaStatus.failure());
  }
}
export function* editLpaStatusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editLpaStatus.REQUEST);
    yield call(editLpaStatusSaga, payload);
  }
}

export function* appointLpaStatusSaga(payload) {
  try {
    const { user } = payload;
    const { status, data } = yield call(api.appointLpaStatus, payload);
    if (status < 300) {
      yield put(ActionTypes.appointLpaStatus.success(data));
      yield call(getUserInfoSaga, { id: user });
    } else {
      yield put(ActionTypes.appointLpaStatus.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.appointLpaStatus.failure());
  }
}

export function* appointLpaStatusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.appointLpaStatus.REQUEST);
    yield call(appointLpaStatusSaga, payload);
  }
}

export function* revokeLpaStatusSaga(id) {
  try {
    const { status, data } = yield call(api.revokeLpaStatus, id);
    if (status < 300) {
      yield put(ActionTypes.revokeLpaStatus.success(data));
      yield call(getUserInfoSaga, { id });
    } else {
      yield put(ActionTypes.revokeLpaStatus.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.revokeLpaStatus.failure());
  }
}

export function* revokeLpaStatusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.revokeLpaStatus.REQUEST);
    yield call(revokeLpaStatusSaga, payload);
  }
}

export function* getLpasSaga({ search }) {
  try {
    yield delay(300);
    const { status, data } = yield call(api.getLpas, search);
    if (status < 300) {
      yield put(ActionTypes.getLpas.success(data));
      return { usersAreLoaded: true, data };
    }
    yield put(ActionTypes.getLpas.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getLpas.failure());
    throw e;
  }
}
export function* getLpasWatcherSaga() {
  yield takeLatest(ActionTypes.GET_LPAS[REQUEST], getLpasSaga);
}

export function* initializeBackofficeUserImportSaga(payload) {
  const config = { headers: { 'content-type': 'multipart/form-data' } };
  const { fromServer, platform } = payload;

  try {
    const sendData = payload.server ? { fromServer, server: payload.server } : { fromServer, file: payload.file[0] };
    const { status, data } = yield call(api.initializeBackofficeUserImport, sendData, config, platform);

    if (status < 300) yield put(ActionTypes.initializeBackofficeUserImport.success());
    else yield put(ActionTypes.initializeBackofficeUserImport.failure(new SubmissionError(data)));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.initializeBackofficeUserImport.failure());
  }
}

export function* initializeBackofficeUserImportWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.initializeBackofficeUserImport.REQUEST);
    yield call(initializeBackofficeUserImportSaga, payload);
  }
}

export function* generateAndDownloadSumSubReportSaga({ userId }) {
  try {
    const { data, headers } = yield call(api.generateAndDownloadSumSubReport, userId);
    const fileName = headers['content-disposition'].replace('attachment; filename=', '').replaceAll('"', '');
    FileDownload(data, fileName);
    yield put(ActionTypes.generateAndDownloadSumSubReport.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.generateAndDownloadSumSubReport.failure());
  }
}

export function* generateAndDownloadSumSubReportWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.generateAndDownloadSumSubReport.REQUEST);
    yield call(generateAndDownloadSumSubReportSaga, payload);
  }
}

export function* getBackofficeUserImportsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getBOUserImports, search);
    if (status < 300) {
      yield put(ActionTypes.getBackofficeUserImports.success(data));
    } else {
      yield put(ActionTypes.getBackofficeUserImports.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getBackofficeUserImports.failure(e));
  }
}
export function* getBackofficeUserImportsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_BO_USER_IMPORTS[REQUEST]);
    yield call(getBackofficeUserImportsSaga, data);
  }
}

export function* downloadBackofficeUserImportSaga({ id, name, ext }) {
  try {
    const { status, data } = yield call(api.downloadBackofficeUserImport, id, ext);
    if (status < 300) {
      yield put(ActionTypes.downloadBackofficeUserImport.success(data));
      FileDownload(data, name);
    } else {
      yield put(acceptError('', true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.downloadBackofficeUserImport.failure(e));
  }
}
export function* downloadBackofficeUserImportWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DOWNLOAD_BO_USER_IMPORT[REQUEST]);
    yield call(downloadBackofficeUserImportSaga, data);
  }
}

export function* generateSecurityCodeSaga(userId) {
  try {
    const { status } = yield call(api.generateSecurityCode, userId);

    if (status < 300) {
      yield put(ActionTypes.generateSecurityPinCode.success());
      yield call(getUserInfoSaga, { id: userId });
    } else {
      yield put(ActionTypes.generateSecurityPinCode.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
  }
}

export function* generateSecurityCodeSagaWatcher() {
  while (true) {
    const { payload } = yield take(ActionTypes.generateSecurityPinCode.REQUEST);
    yield call(generateSecurityCodeSaga, payload);
  }
}

export function* getUserStatusesSaga() {
  try {
    const { data } = yield call(api.getUserStatuses);
    yield put(ActionTypes.getUserStatuses.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserStatuses.failure());
  }
}

export function* getUserStatusesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_USER_STATUSES[REQUEST]);
    yield call(getUserStatusesSaga);
  }
}

export function* depositWalletBalanceSaga({ userId, amount }) {
  try {
    const { data, status } = yield call(api.depositWalletBalance, userId, amount);

    if (status < 300) {
      yield put(ActionTypes.depositWalletBalance.success());
      yield call(getUserDataSaga, userId);
    } else {
      yield put(ActionTypes.depositWalletBalance.failure(new SubmissionError({ _error: data.amount })));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.depositWalletBalance.failure());
  }
}

export function* depositWalletBalanceWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.depositWalletBalance.REQUEST);
    yield call(depositWalletBalanceSaga, payload);
  }
}

export function* withdrawWalletBalanceSaga({ userId, amount }) {
  try {
    const { data, status } = yield call(api.withdrawWalletBalance, userId, amount);

    if (status < 300) {
      yield put(ActionTypes.withdrawWalletBalance.success());
      yield call(getUserDataSaga, userId);
    } else {
      yield put(ActionTypes.withdrawWalletBalance.failure(new SubmissionError({ _error: data.amount })));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.withdrawWalletBalance.failure());
  }
}

export function* withdrawWalletBalanceWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.withdrawWalletBalance.REQUEST);
    yield call(withdrawWalletBalanceSaga, payload);
  }
}

export function* getUserSumSubDocumentsSaga(userId) {
  try {
    const { data, status } = yield call(api.getUserSumSubDocuments, userId);

    if (status < 300) {
      yield put(ActionTypes.getUserSumSubDocuments.success(data));
      yield call(getUserDataSaga, userId);
    } else {
      yield put(ActionTypes.getUserSumSubDocuments.failure(new SubmissionError({ _error: data.detail })));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserSumSubDocuments.failure());
  }
}

export function* getUserSumSubDocumentsWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.GET_USER_SUMSUB_DOCUMENTS[REQUEST]);

    yield call(getUserSumSubDocumentsSaga, userId);
  }
}

export function* downloadUserSumSubDocumentSaga({ userId, name, inspectionId, imageId }) {
  try {
    const { status, data } = yield call(api.downloadUserSumSubDocument, { userId, inspectionId, imageId });

    if (status < 300) {
      yield put(ActionTypes.downloadUserSumSubDocument.success());
      FileDownload(data, `${name}.png`);
    } else {
      yield put(acceptError('', true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.downloadUserSumSubDocument.failure(e));
  }
}
export function* downloadUserSumSubDocumentWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.downloadUserSumSubDocument.REQUEST);
    yield call(downloadUserSumSubDocumentSaga, payload);
  }
}

export default [
  fork(enableSupportMessagesWatcherSaga),
  fork(disableSupportMessagesWatcherSaga),
  fork(appointLpaStatusWatcherSaga),
  fork(revokeLpaStatusWatcherSaga),
  fork(editLpaStatusWatcherSaga),
  fork(getGeonameByGIDWatcherSaga),
  fork(getUsersWatcherSaga),
  fork(getUserDataWatcherSaga),
  fork(getUserInfoWatcherSaga),
  fork(verifyUserWatcherSaga),
  fork(blockUserWatcherSaga),
  fork(unblockUserWatcherSaga),
  fork(verifyPartnerWatcherSaga),
  fork(rejectPartnerWatcherSaga),
  fork(removePhoneWatcherSaga),
  fork(getUserDocumentFileWatcherSaga),
  fork(verifyUserDocumentWatcherSaga),
  fork(rejectUserDocumentWatcherSaga),
  fork(uploadUserDocumentWatcherSaga),
  fork(editUserInfoWatcherSaga),
  fork(getUserAccountWatcherSaga),
  fork(getUserAccountsWatcherSaga),
  fork(blockUserAccountWatcherSaga),
  fork(unblockUserAccountWatcherSaga),
  fork(getUserPaymentsWatcherSaga),
  fork(getUserTotalPaymentsWatcherSaga),
  fork(getUserBonusWatcherSaga),
  fork(createUserBonusWatcherSaga),
  fork(withdrawUserBonusWatcherSaga),
  fork(getUserNotesWatcherSaga),
  fork(createUserNoteWatcherSaga),
  fork(changeUserPasswordWatcherSaga),
  fork(getAllTagsWatcherSaga),
  fork(getUserTagsWatcherSaga),
  fork(setUserTagWatcherSaga),
  fork(deleteUserTagWatcherSaga),
  fork(deleteUserAccountWatcherSaga),
  fork(restoreUserAccountWatcherSaga),
  fork(getUserPartnerAccountsWatcherSaga),
  fork(getPartnershipConditionsListWatcherSaga),
  fork(createUserPartnerAccountWatcherSaga),
  fork(editUserPartnerAccountWatcherSaga),
  fork(editUserCanActivateBonusesWatcherSaga),
  fork(getAvailablePartnerCodesWatcherSaga),
  fork(editUserPartnerCodeWatcherSaga),
  fork(removeUserPartnerCodeWatcherSaga),
  fork(editTradeAccountPartnerCodeWatcherSaga),
  fork(removeTradeAccountUserPartnerCodeWatcherSaga),
  fork(startImpersonateWatcherSaga),
  fork(rejectUserVerificationWatcherSaga),
  fork(getUserIssuesWatcherSaga),
  fork(getUserCardsWatcherSaga),
  fork(getCardPhotoWatcherSaga),
  fork(verifyUserCardWatcherSaga),
  fork(rejectUserCardWatcherSaga),
  fork(addUserCardSagaWatcherSaga),
  fork(getUserAvailableDepositsWatcherSaga),
  fork(activateUserBonusWatcherSaga),
  fork(confirmUserEmailWatcherSaga),
  fork(cancelUserBonusWatcherSaga),
  fork(getUserUtmHistoryWatcherSaga),
  fork(getUserInternalTransfersWatcherSaga),
  fork(getManagerClientWatcherSaga),
  fork(getBackofficeManagerListWatcherSaga),
  fork(setUserManagerWatcherSaga),
  fork(getFreeClientsCountWatcherSaga),
  fork(editUserStatusWatcherSaga),
  fork(createDocumentWatcherSaga),
  fork(getUserNoteDocumentWatcherSaga),
  fork(deleteUserNoteWatcherSaga),
  fork(editUserNoteWatcherSaga),
  fork(removeUserNoteDocumentWatcherSaga),
  fork(getUserKYCWatcherSaga),
  fork(editUserKYCWatcherSaga),
  fork(getTrederoCustomUserKYCWatcherSaga),
  fork(editTrederoCustomUserKYCWatcherSaga),
  fork(generateAndDownloadSumSubReportWatcherSaga),
  fork(withdrawalProofWatcherSaga),
  fork(createUserWatcherSaga),
  fork(getServerListWatcherSaga),
  fork(createUserAccountWatcherSaga),
  fork(getCallProvidersListWatcherSaga),
  fork(makeACallWatcherSaga),
  fork(getCallProvidersListWatcherSaga),
  fork(getLpaInfoWatcherSaga),
  fork(getCurrenciesWatcherSaga),
  fork(getLpasWatcherSaga),
  fork(initializeBackofficeUserImportWatcherSaga),
  fork(getBackofficeUserImportsWatcherSaga),
  fork(downloadBackofficeUserImportWatcherSaga),
  fork(getUserOperationHistoryWatcherSaga),
  fork(getUserLoginHistoryWatcherSaga),
  fork(generateSecurityCodeSagaWatcher),
  fork(getUserStatusesWatcherSaga),
  fork(depositWalletBalanceWatcherSaga),
  fork(withdrawWalletBalanceWatcherSaga),
  fork(getUserSumSubDocumentsWatcherSaga),
  fork(downloadUserSumSubDocumentWatcherSaga),
];
